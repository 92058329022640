import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { TranslatePipe } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IconComponent } from 'sfx-commons';
import { MAIN_ROUTES, OUTLET_NAMES, PATH_NAMES_PER_OUTLETS } from '../../constants';
import { INews } from '../../projects/models/INews';
import { NewsService } from '../../projects/services/news.service';

@Component({
    selector: 'app-news-bar',
    templateUrl: './news-bar.component.html',
    styleUrls: ['./news-bar.component.scss'],
    imports: [NgIf, AsyncPipe, TranslatePipe, IconComponent]
})
export class NewsBarComponent {
    @Input({ required: true })
    public news: Array<INews>;

    public readonly hasUnreadNews$: Observable<boolean>;
    public readonly xMarkIcon = faXmark;

    constructor(
        private activatedRoute: ActivatedRoute,
        private newsSvc: NewsService,
        private router: Router
    ) {
        this.hasUnreadNews$ = this.newsSvc.hasUnreadNews$;
    }

    public hideModal = async () => {
        await this.router.navigate([{ outlets: { [OUTLET_NAMES.NEWS_MODAL_OUTLET]: null } }], {
            relativeTo: this.activatedRoute.parent,
            queryParamsHandling: 'preserve'
        });
    };

    public hideNews = () => {
        this.newsSvc.updateUnreadNewsStatus(false);
    };

    public openModal = async () => {
        await this.router.navigate(
            [
                MAIN_ROUTES.PROJECTS,
                {
                    outlets: {
                        [OUTLET_NAMES.NEWS_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.NEWS_MODAL_OUTLET]
                    }
                }
            ],
            {
                relativeTo: this.activatedRoute,
                queryParamsHandling: 'preserve'
            }
        );

        this.newsSvc.updateUnreadNewsStatus(false);
    };
}
