import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { differenceInSeconds, intervalToDuration } from 'date-fns';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Pipe({
    name: 'sfxTime'
})
export class TimePipe implements PipeTransform {
    private readonly timeFormatInMinutes = "m'm'";
    private readonly timeFormatInSeconds = "s's'";
    private readonly timeFormat = `${this.timeFormatInMinutes}\u00a0${this.timeFormatInSeconds}`; //\u00a0 - unicode character for non-breaking space

    constructor(@Inject(LOCALE_ID) private readonly locale: string) {}

    public transform(value: number): Observable<string> {
        return timer(0, 1000).pipe(
            untilDestroyed(this),
            map(() => {
                const end = differenceInSeconds(new Date(value), Date.now());
                const duration = intervalToDuration({ start: 0, end: end * 1000 });

                const date = new Date();
                date.setMinutes(duration.minutes);
                date.setSeconds(duration.seconds);

                let format: string;
                if (!duration.minutes) {
                    format = this.timeFormatInSeconds;
                } else if (!duration.seconds) {
                    format = this.timeFormatInMinutes;
                } else {
                    format = this.timeFormat;
                }

                return formatDate(date, format, this.locale);
            })
        );
    }
}
