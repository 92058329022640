import { LANGUAGE_FILE_NAME_SUFFIX, SUPPORTED_LANGUAGE } from '../app/constants';
import { ObjectUtils } from '../app/core/utils/object.utils';

export function parseLanguages(localesJson: { locales: Array<string>; prefix: string }): Array<string> {
    return localesJson.locales.map(
        (lang: string): SUPPORTED_LANGUAGE =>
            SUPPORTED_LANGUAGE[
                ObjectUtils.getKeyForValue<keyof typeof SUPPORTED_LANGUAGE>(LANGUAGE_FILE_NAME_SUFFIX, lang)
            ]
    );
}
