import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NOTIFICATION_TYPES } from '../../constants';
import { IAssignDocument } from '../../projects/models/IAssignDocument';
import { IDocument } from '../../projects/models/IDocument';
import { IInvalidDocumentsAndDocumentGroups } from '../../projects/models/IInvalidDocumentsAndDocumentGroups';
import { IRequiredDocumentGroup } from '../../projects/models/IRequiredDocumentGroup';
import { DocumentsService } from '../../projects/services/documents.service';
import { ModalUtils } from './modal.utils';

export class DocumentUtils {
    public static areThereEnabledDocumentGroups(documentSvc: DocumentsService): boolean {
        const reqDocs = documentSvc.getCachedRequiredDocuments();
        if (reqDocs) {
            const enabledDocGroup = reqDocs.categories.find((c) => c.groups.find((g) => !g.isDisabled));

            return !!enabledDocGroup;
        }

        return false;
    }

    public static areThereNewDocumentsAdded(documentSvc: DocumentsService): boolean {
        const reqDocs = documentSvc.getCachedRequiredDocuments();
        if (reqDocs) {
            const areThereNewDocsAdded = !!reqDocs.categories.find((c) =>
                c.groups.find((g) => g.assignedDocuments.find((d) => d.isNew))
            );
            if (areThereNewDocsAdded) {
                return true;
            }
        }

        return false;
    }

    public static areThereTooBigFiles(documentSvc: DocumentsService): boolean {
        const reqDocs = documentSvc.getCachedRequiredDocuments();
        if (reqDocs) {
            const areTooBigFilesAdded = !!reqDocs.categories.find((c) =>
                c.groups.find((g) => g.assignedDocuments.find((d) => d.isTooBig))
            );
            if (areTooBigFilesAdded) {
                return true;
            }
        }

        return false;
    }

    public static areThereAnyDocumentsUploaded(documentSvc: DocumentsService): boolean {
        const reqDocs = documentSvc.getCachedRequiredDocuments();
        if (reqDocs) {
            const areThereAnyDocsAdded = !!reqDocs.categories.find((c) =>
                c.groups.find((g) => !!g.assignedDocuments.length)
            );
            if (areThereAnyDocsAdded) {
                return true;
            }
        }

        return false;
    }

    public static isNewValidDocument(document: IDocument, ignoreValidity = false): boolean {
        return document.isNew && (document.isValid || ignoreValidity) && !document.isTooBig;
    }

    public static areThereAnyNewValidDocuments(documentSvc: DocumentsService, ignoreValidity = false): boolean {
        const reqDocs = documentSvc.getCachedRequiredDocuments();
        if (!!reqDocs) {
            const areThereAnyNewValidDocuments = !!reqDocs.categories.find((c) =>
                c.groups.find(
                    (g) =>
                        !g.isDisabled &&
                        !!g.assignedDocuments.find((d) => DocumentUtils.isNewValidDocument(d, ignoreValidity))
                )
            );
            if (areThereAnyNewValidDocuments) {
                return true;
            }
        }

        return false;
    }

    public static async assignDocumentsToGroups(
        documentSvc: DocumentsService,
        projectId: number,
        payload: Array<IAssignDocument>
    ): Promise<{
        documents?: Array<IDocument>;
        messageType: string;
        messageTitle: string;
        messageContentKey: string;
        errorMessage?: string;
    }> {
        const messageTitle = 'documents.assign_document_title';
        const originalDocumentGroups = {};

        for (const document of payload) {
            const movedDocument = documentSvc.removeDocumentFromRequiredDocumentCache(projectId, document.documentId);

            if (movedDocument) {
                documentSvc.addDocumentToRequiredDocumentsCache({
                    ...movedDocument,
                    documentGroup: document.group,
                    lenderId: document.lenderId
                });
                originalDocumentGroups[movedDocument.documentId] = {
                    name: movedDocument.documentGroup,
                    lenderId: movedDocument.lenderId
                };
            }
        }

        try {
            const documents = await documentSvc.assignDocumentsToGroups(projectId, payload);
            for (const document of documents) {
                documentSvc.updateDocumentInRequiredDocumentsCache(document);
            }

            return {
                documents,
                messageType: NOTIFICATION_TYPES.SUCCESS,
                messageTitle,
                messageContentKey: 'documents.assign_document_successful'
            };
        } catch (ex) {
            console.error(ex);

            for (const document of payload) {
                const movedDocument = documentSvc.removeDocumentFromRequiredDocumentCache(
                    projectId,
                    document.documentId
                );

                if (movedDocument) {
                    documentSvc.addDocumentToRequiredDocumentsCache({
                        ...movedDocument,
                        documentGroup: originalDocumentGroups[document.documentId].name,
                        lenderId: originalDocumentGroups[document.documentId].lenderId
                    });
                }
            }

            return {
                messageType: NOTIFICATION_TYPES.ERROR,
                messageTitle,
                messageContentKey: 'documents.assign_document_failed',
                errorMessage: ex.error?.message
            };
        }
    }

    public static navigateByUrlAfterClickToDocument = (
        router: Router,
        activatedRoute: ActivatedRoute,
        id: number | 'NULL'
    ): void => {
        if (!id) {
            return;
        }

        const { documentId } = activatedRoute.snapshot.params;

        if (documentId) {
            if (id === 'NULL') {
                router.navigate(['../'], { relativeTo: activatedRoute });
                return;
            } else {
                router.navigateByUrl(router.url.replace(documentId, id.toString()));
                return;
            }
        }

        if (id === 'NULL') {
            return;
        }
        router.navigate(['./', id], { relativeTo: activatedRoute });
    };

    public static getInvalidDocumentsAndDocumentGroups(
        documentSvc: DocumentsService
    ): IInvalidDocumentsAndDocumentGroups {
        const requiredDocuments = documentSvc.getCachedRequiredDocuments();
        const invalidDocuments: Array<IDocument> = [];
        const invalidDocumentGroups: Array<IRequiredDocumentGroup> = [];

        if (requiredDocuments?.categories?.length) {
            requiredDocuments.categories.forEach((category) => {
                if (category.groups?.length) {
                    category.groups.forEach((group) => {
                        if (group.assignedDocuments?.length) {
                            invalidDocuments.push(...group.assignedDocuments.filter((document) => !document.isValid));
                        }

                        if (!group.isValid) {
                            invalidDocumentGroups.push(group);
                        }
                    });
                }
            });
        }

        return { invalidDocuments, invalidDocumentGroups };
    }

    public static doDocumentsNeedActionBeforeLeaving(documentsSvc: DocumentsService): boolean {
        const areThereDocGroupsEnabled = DocumentUtils.areThereEnabledDocumentGroups(documentsSvc);
        if (!areThereDocGroupsEnabled) {
            return false;
        }

        const areThereNewDocsAdded = DocumentUtils.areThereAnyNewValidDocuments(documentsSvc);
        if (areThereNewDocsAdded) {
            return true;
        }

        return false;
    }

    public static confirmIfShouldProceedWithNavigatingAway(
        documentsSvc: DocumentsService,
        modalSvc: NzModalService,
        translate: TranslateService
    ): Promise<boolean> {
        const doDocumentsNeedActionBeforeLeaving = DocumentUtils.doDocumentsNeedActionBeforeLeaving(documentsSvc);
        if (doDocumentsNeedActionBeforeLeaving) {
            const { isConfirmedPromise } = ModalUtils.createDocumentsNotSubmittedConfirmation(modalSvc, translate);
            return isConfirmedPromise;
        }

        return Promise.resolve(true);
    }
}
