import { Injectable } from '@angular/core';
import { Operation } from 'fast-json-patch';
import { Observable, Subject } from 'rxjs';
import { ROUTE_ID } from '../../constants/api-routes';

@Injectable({
    providedIn: 'root'
})
export class OfferTriggerChangeService {
    public doOffersNeedToBeGenerated: Observable<void>;
    public hasOfferTriggeringChangeOccurred: Observable<void>;
    private doOffersNeedToBeGeneratedSubject: Subject<void>;
    private hasOfferTriggeringChangeOccurredSubject: Subject<void>;

    private readonly deleteOfferTriggeringRouteIds = [ROUTE_ID.BORROWER_BY_ID];
    private readonly getOfferTriggeringRouteIds = [];
    private readonly postOfferTriggeringRouteIds = [
        ROUTE_ID.BORROWERS_FOR_PROJECT,
        ROUTE_ID.BORROWERS_TYPE,
        ROUTE_ID.CALCULATE_QUICK_FLAT_VALUATION,
        ROUTE_ID.CALCULATE_QUICK_HOUSE_VALUATION,
        ROUTE_ID.CALCULATE_FLAT_FULL_VALUATION,
        ROUTE_ID.CALCULATE_HOUSE_FULL_VALUATION,
        ROUTE_ID.CLONE_PROJECT
    ];

    private readonly jsonPatchOfferTriggerExceptionFieldsBorrower = [];
    private readonly jsonPatchOfferTriggerExceptionFieldsCollateral = [];
    private readonly jsonPatchOfferTriggerExceptionFieldsFinancing = [];
    private readonly jsonPatchOfferTriggerExceptionFieldsProperty = [];

    private readonly jsonPatchOfferTriggerExceptionRoutesBorrower = [];
    private readonly jsonPatchOfferTriggerExceptionRoutesCollateral = [];
    private readonly jsonPatchOfferTriggerExceptionRoutesProperty = [];
    private readonly jsonPatchOfferTriggerExceptionRoutesFinancing = [
        ROUTE_ID.FINANCINGS_FOR_PROJECT_EXTENDED_ASSUMPTION
    ];

    private readonly immediateOfferTriggeringRouteIds: Array<{
        method: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT';
        routeId: ROUTE_ID;
    }> = [
        { method: 'PATCH', routeId: ROUTE_ID.BORROWER_BY_ID },
        { method: 'PATCH', routeId: ROUTE_ID.BORROWERS_LEGAL_ENTITIES_BY_ID },
        { method: 'DELETE', routeId: ROUTE_ID.BORROWER_BY_ID },
        { method: 'POST', routeId: ROUTE_ID.BORROWERS_FOR_PROJECT },
        { method: 'POST', routeId: ROUTE_ID.BORROWERS_TYPE },
        { method: 'PATCH', routeId: ROUTE_ID.COLLATERALS_FOR_PROJECT },
        { method: 'PATCH', routeId: ROUTE_ID.FINANCINGS_FOR_PROJECT },
        { method: 'PATCH', routeId: ROUTE_ID.UPDATE_SHORT_PROPERTY }
    ];

    private readonly patchBorrowerRoutes: Array<ROUTE_ID> = [];
    private readonly patchFinancingRoutes: Array<ROUTE_ID> = [];
    private readonly patchPropertyRoutes: Array<ROUTE_ID> = [];
    private readonly patchCollateralRoutes: Array<ROUTE_ID> = [];

    constructor() {
        this.doOffersNeedToBeGeneratedSubject = new Subject<void>();
        this.doOffersNeedToBeGenerated = this.doOffersNeedToBeGeneratedSubject.asObservable();
        this.hasOfferTriggeringChangeOccurredSubject = new Subject<void>();
        this.hasOfferTriggeringChangeOccurred = this.hasOfferTriggeringChangeOccurredSubject.asObservable();

        Object.values(ROUTE_ID).map((routeId) => {
            if (
                routeId.indexOf('BORROWER') > -1 &&
                !this.jsonPatchOfferTriggerExceptionRoutesBorrower.includes(routeId)
            ) {
                this.patchBorrowerRoutes.push(routeId);
            } else if (
                routeId.indexOf('FINANCING') > -1 &&
                !this.jsonPatchOfferTriggerExceptionRoutesFinancing.includes(routeId)
            ) {
                this.patchFinancingRoutes.push(routeId);
            } else if (
                routeId.indexOf('COLLATERAL') > -1 &&
                !this.jsonPatchOfferTriggerExceptionRoutesCollateral.includes(routeId)
            ) {
                this.patchCollateralRoutes.push(routeId);
            } else if (
                (routeId.indexOf('PROPERTY') > -1 || routeId.indexOf('VALUATION') > -1) &&
                !this.jsonPatchOfferTriggerExceptionRoutesProperty.includes(routeId)
            ) {
                this.patchPropertyRoutes.push(routeId);
            }
        });
    }

    public didOfferTriggeringChangeOccur(): void {
        this.hasOfferTriggeringChangeOccurredSubject.next();
    }

    public didImmediateOfferGeneratingTriggerHappen(
        method: 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT',
        routeId: ROUTE_ID
    ): boolean {
        return !!this.immediateOfferTriggeringRouteIds.find((op) => op.method === method && op.routeId === routeId);
    }

    public doesDeleteRouteTriggerOfferChange(routeId: ROUTE_ID): boolean {
        return this.deleteOfferTriggeringRouteIds.indexOf(routeId) > -1;
    }

    public doesGetRouteTriggerOfferChange(routeId: ROUTE_ID): boolean {
        return this.getOfferTriggeringRouteIds.indexOf(routeId) > -1;
    }

    public doesPostRouteTriggerOfferChange(routeId: ROUTE_ID): boolean {
        return this.postOfferTriggeringRouteIds.indexOf(routeId) > -1;
    }

    public hasPatchOfferTriggeringMutation(routeId: ROUTE_ID, mutations: Array<Operation>): Array<Operation> {
        return mutations.filter((mutation) => {
            const mutationNameSplit = mutation.path.split('/');
            const mutatedFieldName = mutationNameSplit[mutationNameSplit.length - 1];
            let exceptionCollection;
            if (this.patchBorrowerRoutes.indexOf(routeId) > -1) {
                exceptionCollection = this.jsonPatchOfferTriggerExceptionFieldsBorrower;
            } else if (this.patchCollateralRoutes.indexOf(routeId) > -1) {
                exceptionCollection = this.jsonPatchOfferTriggerExceptionFieldsCollateral;
            } else if (this.patchFinancingRoutes.indexOf(routeId) > -1) {
                exceptionCollection = this.jsonPatchOfferTriggerExceptionFieldsFinancing;
            } else if (this.patchPropertyRoutes.indexOf(routeId) > -1) {
                exceptionCollection = this.jsonPatchOfferTriggerExceptionFieldsProperty;
            }

            return exceptionCollection && exceptionCollection.indexOf(mutatedFieldName) === -1;
        });
    }

    public signalOfferGenerationTrigger(): void {
        this.doOffersNeedToBeGeneratedSubject.next();
    }

    public signalInstantOfferGeneration() {
        this.didOfferTriggeringChangeOccur();
        this.signalOfferGenerationTrigger();
    }
}
