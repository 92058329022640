import { ErrorHandler } from '@angular/core';
import { SafeAny } from 'sfx-commons';
import { DiagnosticLog } from '../../constants/types';

/*
    Global error handler provided in the app.config.ts
*/
export class SfxErrorHandler implements ErrorHandler {
    private isAppReloadRequested: boolean;

    public handleError(error: SafeAny): void {
        console.error(error);

        if (
            error instanceof TypeError &&
            error.message?.includes('dynamically imported module') &&
            !this.isAppReloadRequested
        ) {
            this.isAppReloadRequested = true;

            const diagnosticLogsStr = localStorage.getItem('DIAGNOSTIC_LOGS');
            if (diagnosticLogsStr) {
                let diagnosticLogs = JSON.parse(diagnosticLogsStr) as Array<DiagnosticLog>;
                if (!diagnosticLogs) {
                    diagnosticLogs = [];
                }
                diagnosticLogs.splice(0, 0, { key: 'SfxErrorHandler', value: error.message });
                localStorage.setItem('DIAGNOSTIC_LOGS', JSON.stringify(diagnosticLogs));
            }

            window.location.reload();
        }
    }
}
