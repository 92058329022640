import { NgIf } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonComponent, InfoComponent, LoaderComponent } from 'sfx-commons';
import { MAIN_ROUTES, SafeAny } from '../constants';
import { RouterService } from '../core/services/router.service';
import { AuthenticationService } from '../projects/services/authentication.service';

@Component({
    templateUrl: './key4-training-completed.component.html',
    styleUrls: ['./key4-training-completed.component.scss'],
    imports: [NgIf, ButtonComponent, InfoComponent, LoaderComponent]
})
export class Key4TrainingCompletedComponent implements OnInit, OnDestroy {
    public didCallFail = false;
    public hasWaitedAnExtendedPeriod = false;
    public isCallInProgress = false;

    private timeout: SafeAny;

    constructor(
        private readonly authSvc: AuthenticationService,
        private readonly routerSvc: RouterService
    ) {}

    public async ngOnInit(): Promise<void> {
        this.timeout = setTimeout(() => {
            this.hasWaitedAnExtendedPeriod = true;
        }, 10000);

        const user = await this.authSvc.getCurrentUser();
        if (user) {
            await this.retryCall();
        }
    }

    public ngOnDestroy(): void {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    public async retryCall(): Promise<void> {
        this.didCallFail = false;
        this.isCallInProgress = true;
        try {
            await this.authSvc.markKey4TrainingAsCompleted();
            await this.redirectToProjects();
        } catch {
            this.didCallFail = true;
        } finally {
            this.isCallInProgress = false;
        }
    }

    public async redirectToProjects(): Promise<void> {
        await this.routerSvc.navigateToRoute([MAIN_ROUTES.PROJECTS]);
    }
}
