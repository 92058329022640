import { Injectable } from '@angular/core';
import { SafeAny } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class DomUpdateSchedulerService {
    private activeComponentTimeout;
    private activeElement;
    private scheduledActions = {};
    private scheduledActionKeys = [];
    private timeout;

    private routePaths = [];
    private routeComponents = {};

    constructor() {
        this.activeElement = window.document.activeElement.nodeName === 'INPUT' ? window.document.activeElement : null;

        window.addEventListener(
            'focus',
            (e) => {
                if ((e.target as Node).nodeName === 'INPUT') {
                    this.activeElement = e.target;
                }
            },
            true
        );

        window.addEventListener(
            'blur',
            (e) => {
                setTimeout(() => {
                    if (e.target === this.activeElement && document.activeElement.nodeName !== 'INPUT') {
                        this.activeElement = null;
                        if (this.timeout) {
                            clearTimeout(this.timeout);
                        }

                        this.timeout = setTimeout(() => {
                            if (!this.activeElement) {
                                clearTimeout(this.timeout);
                                this.runScheduledActions();
                            } else {
                                this.updateActiveComponent();
                            }
                        }, 250);
                    }
                }, 100);
            },
            true
        );
    }

    /**
     * Schedule action for focus control in app
     * @param name - a key for the scheduled action
     * @param event - event to be scheduled for when no input is focused and background updates are safe to perform
     * @param instantEvent - this is triggered without scheduling, used for triggering total updates for active modals
     */
    public scheduleAction = async (name: string, event, instantEvent = (): void | Promise<void> => null) => {
        if (this.activeElement || this.timeout) {
            if (!this.scheduledActionKeys.length) {
                this.updateActiveComponent();
            }

            this.scheduledActions[name] = event;
            const actionKeyIndex = this.scheduledActionKeys.findIndex((actionKey) => actionKey === name);
            if (actionKeyIndex > -1) {
                this.scheduledActionKeys.splice(actionKeyIndex, 1);
            }
            this.scheduledActionKeys.push(name);

            await instantEvent();
        } else {
            await event();
        }
    };

    public updatePathAndComponents = (routePaths: Array<SafeAny>, routeComponents: SafeAny) => {
        this.routePaths = routePaths;
        this.routeComponents = routeComponents;
    };

    public updateActiveComponent = () => {
        if (!this.activeElement) {
            window.__ACTIVE_COMP__ = null;
            clearTimeout(this.activeComponentTimeout);
        } else {
            window.__ACTIVE_COMP__ = this.routePaths.length
                ? this.routeComponents[this.routePaths[this.routePaths.length - 1]]
                : null;
        }
    };

    private runScheduledActions() {
        this.updateActiveComponent();
        this.scheduledActionKeys.map((eventKey) => this.scheduledActions[eventKey]());
        this.scheduledActions = {};
        this.scheduledActionKeys = [];
    }
}
