import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Operation } from 'fast-json-patch';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { calculateMutations, isNullOrUndefined } from 'sfx-commons';
import { ROUTE_ID } from '../../constants/api-routes';
import { HttpService } from '../../core/services/http.service';
import { BorrowerUtils } from '../../core/utils/borrower.utils';
import { ModalUtils } from '../../core/utils/modal.utils';
import { getHttpStatusCodeFromError, isCancelledRequestError } from '../../core/utils/utils';
import { BorrowerType } from '../models/enums/borrowerType';
import { BusinessType } from '../models/enums/businessType';
import { ObjectType } from '../models/enums/objectType';
import { ProjectStatus } from '../models/enums/projectStatus';
import { CachedProjectsData, ICachedProjectData } from '../models/ICachedProjectData';
import { ICopyProjectDataPayload } from '../models/ICopyProjectDataPayload';
import { IExtendedBorrower } from '../models/IExtendedBorrower';
import { IGetLatestModifiedProjectsFilter } from '../models/IGetLatestModifiedProjectsFilter';
import { IListablePagedResult } from '../models/IListablePagedResult';
import { IListableProject } from '../models/IListableProject';
import { IProject } from '../models/IProject';
import { IProjectStatus } from '../models/IProjectStatus';
import { IRevokeProjectData } from '../models/IRevokeProjectData';
import { IUnsupportedPartnersPerBusinessType } from '../models/IUnsupportedPartnersPerBusinessType';
import { IWrappedBoolean } from '../models/IWrappedBoolean';
import { AuthorizationService } from './authorization.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectsService {
    public allProjectStatuses: Observable<Array<IProjectStatus>>;
    public projectToImportObservable: Observable<IListableProject>;
    public listedProjectVersionObservable: Observable<{ previousProjectId: number; newProject: IListableProject }>;
    public cachedProjectsObservable: Observable<NonNullable<CachedProjectsData>>;
    public cachedProjectDataObservable: (id: number) => Observable<ICachedProjectData>;
    public cachedProjectObservable: (id: number) => Observable<IProject>;

    private allProjectStatusesSubject = new BehaviorSubject<Array<IProjectStatus>>(null);
    private allProjectStatusesHttpRequestPromise: Promise<Array<IProjectStatus>> = null;
    private listableProjects: IListablePagedResult<IListableProject>;
    private cachedProjectsSubject = new BehaviorSubject<NonNullable<CachedProjectsData>>(
        new Map<number, ICachedProjectData>()
    );
    private projectToImportSubject = new BehaviorSubject<IListableProject>(null);
    private listedProjectVersionSubject = new BehaviorSubject<{
        previousProjectId: number;
        newProject: IListableProject;
    }>(null);
    private unsupportedPartnersPerBusinessTypeSubject = new BehaviorSubject<Array<IUnsupportedPartnersPerBusinessType>>(
        null
    );
    private readonly defaultLatestModifiedProjectsFilter: IGetLatestModifiedProjectsFilter = {
        skip: 0,
        take: 5
    };

    constructor(
        private readonly authorizationSvc: AuthorizationService,
        private readonly httpService: HttpService,
        private readonly modalSvc: NzModalService,
        private readonly translate: TranslateService
    ) {
        this.allProjectStatuses = this.allProjectStatusesSubject.asObservable();
        this.projectToImportObservable = this.projectToImportSubject.asObservable();
        this.listedProjectVersionObservable = this.listedProjectVersionSubject.asObservable();

        this.cachedProjectsObservable = this.cachedProjectsSubject.asObservable();
        this.cachedProjectDataObservable = (id: number) =>
            this.cachedProjectsObservable.pipe(
                map((cachedProjectsData: CachedProjectsData) => cachedProjectsData.get(id)),
                distinctUntilChanged()
            );
        this.cachedProjectObservable = (id: number) =>
            this.cachedProjectsObservable.pipe(
                // intentionally duplicate to reduce coupling
                map((cachedProjectsData: CachedProjectsData) => cachedProjectsData.get(id)?.project),
                distinctUntilChanged()
            );
    }

    public clearCache(): void {
        this.allProjectStatusesSubject.next(undefined);
        this.projectToImportSubject.next(undefined);
        this.listedProjectVersionSubject.next(undefined);
        this.unsupportedPartnersPerBusinessTypeSubject.next(undefined);
        this.listableProjects = undefined;
        this.clearProjectSpecificCache();
    }

    public clearProjectSpecificCache(): void {
        // Clear entire project cache since we receive update notifications only for the opened project
        this.cachedProjectsSubject.next(new Map<number, ICachedProjectData>());
        this.authorizationSvc.setOpenedProjectPermissionMap(undefined);
    }

    public async getLatestModifiedProjects(
        queryParams: IGetLatestModifiedProjectsFilter = this.defaultLatestModifiedProjectsFilter,
        cancellationContext?: object
    ): Promise<IListablePagedResult<IListableProject>> {
        const response = await this.httpService.getWithQuery<IListablePagedResult<IListableProject>>(
            ROUTE_ID.LATEST_MODIFIED_PROJECTS,
            queryParams,
            null,
            null,
            null,
            cancellationContext
        );

        if (!this.listableProjects || !response.skip) {
            this.listableProjects = response;
        } else {
            const listableProjectsList = this.listableProjects.data;
            this.listableProjects = {
                ...response,
                data: [...listableProjectsList, ...response.data]
            };
        }

        return this.listableProjects;
    }

    public cancelGetLatestModifiedProjects(): void {
        this.httpService.cancelRequest(ROUTE_ID.LATEST_MODIFIED_PROJECTS, 'GET');
    }

    public async bindOffersForProject(id: number, payload: { language: string }): Promise<IProject> {
        const project = await this.httpService.patch<IProject>(
            ROUTE_ID.OFFERS_BIND,
            {},
            {
                id,
                ...payload
            },
            null,
            null,
            {}
        );

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public cancelBindOffersForProject(): void {
        this.httpService.cancelRequest(ROUTE_ID.OFFERS_BIND, 'PATCH');
    }

    public async cloneProject(id: number): Promise<IProject> {
        return await this.httpService.post<IProject>(ROUTE_ID.CLONE_PROJECT, {}, { id });
    }

    public async copyProjectData(payload: ICopyProjectDataPayload): Promise<IProject> {
        return await this.httpService.post<IProject>(ROUTE_ID.COPY_PROJECT_DATA, {}, payload);
    }

    public async createProject(
        title: string,
        businessType: BusinessType,
        objectType: ObjectType,
        lenderId?: number,
        ownerMail?: string,
        firstBorrower?: IExtendedBorrower
    ): Promise<IProject> {
        return await this.httpService.post<IProject>(
            ROUTE_ID.CREATE_PROJECT,
            {},
            {
                title,
                businessType,
                objectType,
                lenderId,
                ownerMail,
                firstBorrower
            }
        );
    }

    public async acceptOffer(
        projectId: number,
        id: number,
        executeBeforeUpdatingProject?: () => Promise<void>
    ): Promise<IProject> {
        const project = await this.httpService.patch<IProject>(ROUTE_ID.OFFER_ACCEPT, undefined, { projectId, id });

        if (executeBeforeUpdatingProject) {
            await executeBeforeUpdatingProject();
        }

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async cancelAcceptedOfferForProject(
        id: number,
        executeBeforeUpdatingProject?: () => Promise<void>
    ): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.OFFERS_CANCEL_ACCEPTED_OFFER, {}, { id });

        if (executeBeforeUpdatingProject) {
            await executeBeforeUpdatingProject();
        }

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async deleteProject(id: number): Promise<boolean> {
        return await this.httpService.delete<boolean>(ROUTE_ID.PROJECT_BY_ID, {}, { id });
    }

    public async fixInterestRateForProject(id: number, interestRateFixedMessage: string): Promise<IProject> {
        const project = await this.httpService.post<IProject>(
            ROUTE_ID.OFFERS_FIX_INTEREST_RATE,
            {},
            {
                id,
                interestRateFixedMessage
            }
        );

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async getProject(
        id: number,
        doGetFromServer = true,
        actionBeforeNotification?: (project: IProject) => void,
        cancellationContext: object = {}
    ): Promise<IProject> {
        try {
            let project: IProject;
            if (!doGetFromServer) {
                project = this.getCachedProject(id);
            }
            if (doGetFromServer || !project || !project.projectStatus || project.id !== id) {
                project = await this.httpService.get<IProject>(
                    null,
                    ROUTE_ID.PROJECT_BY_ID,
                    { id },
                    null,
                    null,
                    cancellationContext
                );
                if (actionBeforeNotification) {
                    actionBeforeNotification(project);
                }

                this.updateProjectInCache(project?.id, project);
            }

            return project;
        } catch (ex) {
            if (!isCancelledRequestError(ex)) {
                this.updateProjectInCache(id, undefined, getHttpStatusCodeFromError(ex));
            }

            throw ex;
        }
    }

    public cancelGetProjectRequest(): void {
        this.httpService.cancelRequest(ROUTE_ID.PROJECT_BY_ID, 'GET');
    }

    public async getListableProjectById(id: number): Promise<IListableProject> {
        return await this.httpService.get<IListableProject>(null, ROUTE_ID.LISTABLE_PROJECT_BY_ID, { id });
    }

    public async getAllProjectStatuses(): Promise<Array<IProjectStatus>> {
        if (!isNullOrUndefined(this.allProjectStatusesHttpRequestPromise)) {
            return await this.allProjectStatusesHttpRequestPromise;
        } else {
            try {
                this.allProjectStatusesHttpRequestPromise = this.httpService.get<Array<IProjectStatus>>(
                    null,
                    ROUTE_ID.ALL_PROJECT_STATUSES
                );
                const allProjectStatuses = await this.allProjectStatusesHttpRequestPromise;
                this.allProjectStatusesHttpRequestPromise = null;
                this.allProjectStatusesSubject.next(allProjectStatuses);
                return allProjectStatuses;
            } catch (ex) {
                this.allProjectStatusesHttpRequestPromise = null;
                return Promise.reject(ex);
            }
        }
    }

    public async getProjectStatuses(id: number): Promise<Array<IProjectStatus>> {
        return await this.httpService.get<Array<IProjectStatus>>(null, ROUTE_ID.PROJECT_STATUSES_BY_ID, { id });
    }

    public getCachedProject(projectId: number, getNewInstance = false): IProject {
        const projectData = this.cachedProjectsSubject.getValue().get(projectId);

        if (getNewInstance && !!projectData?.project) {
            return structuredClone(projectData.project);
        }

        return projectData?.project;
    }

    public doesOpenedProjectNeedActionBeforeLeavingBasedOnStatus(projectStatus: ProjectStatus): boolean {
        return (
            !!projectStatus &&
            (projectStatus === ProjectStatus.OffersBound ||
                projectStatus === ProjectStatus.PreliminaryCheckInquiryCreated)
        );
    }

    public async markAllDocumentsAsCompleted(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(
            ROUTE_ID.DOCUMENTS_COMPLETE,
            {},
            {
                id
            }
        );

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async rejectProject(id: number, payload: IRevokeProjectData): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.REJECT_PROJECT, {}, { id, ...payload });

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async requestFinancingConfirmationForProject(
        id: number,
        payload: { language: string; withObjectData: boolean }
    ): Promise<IProject> {
        const project = await this.httpService.post<IProject>(
            ROUTE_ID.PROJECT_REQUEST_FINANCING_CONFIRMATION,
            {},
            {
                id,
                ...payload
            }
        );

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async requestFixableOfferForProject(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.OFFERS_REQUEST_FIXABLE_OFFER, {}, { id });

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async resetProjectStatusDueToIncompleteData(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(
            ROUTE_ID.RESET_PROJECT_STATUS_DUE_TO_INCOMPLETE_DATA,
            {},
            { id }
        );

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async startCompetenceCenterCheck(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.START_COMPETENCE_CENTER_CHECK, {}, { id });

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async submitDocumentsToLenders(id: number, requestId?: string): Promise<IProject> {
        const project = await this.httpService.post<IProject>(
            ROUTE_ID.SUBMIT_DOCUMENTS_TO_LENDERS,
            {},
            { id, requestId }
        );

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async submitDocumentsToServiceCenter(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.SUBMIT_DOCUMENTS_TO_SERVICE_CENTER, {}, { id });

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public async isOfferBindingCancellationPossibleForProject(id: number): Promise<IWrappedBoolean> {
        return await this.httpService.get<IWrappedBoolean>(null, ROUTE_ID.IS_OFFER_BINDING_CANCELLATION_POSSIBLE, {
            id
        });
    }

    public async updateSubmissionData(id: number, mutations: Array<Operation>): Promise<IProject> {
        const project = await this.httpService.jsonPatch<IProject>(
            null,
            ROUTE_ID.UPDATE_PROJECT_SUBMISSION_DATA,
            { id },
            null,
            mutations
        );
        this.updateProjectInCache(project?.id, project);

        return project;
    }

    public getBorrowerNamesArray(project: IListableProject): Array<string> {
        if (
            !project ||
            (project.borrowersType === BorrowerType.Individual && !project.firstBorrower) ||
            (project.borrowersType === BorrowerType.LegalEntity && !project.legalEntity)
        ) {
            return [];
        }
        const borrowersNames: Array<string> = [];

        if (project.borrowersType === BorrowerType.Individual) {
            const { firstBorrower, secondBorrower, thirdBorrower } = project;
            const calculatedBorrowerNames = BorrowerUtils.getIndividualBorrowerNames([
                firstBorrower,
                secondBorrower,
                thirdBorrower
            ]);
            borrowersNames.push(...calculatedBorrowerNames);
        } else if (project.borrowersType === BorrowerType.LegalEntity) {
            if (project.legalEntity?.name) {
                borrowersNames.push(project.legalEntity.name.trim());
            }
        }

        return borrowersNames;
    }

    public async canSelectProjectForImport(project: IListableProject): Promise<boolean> {
        const currentSelection = this.projectToImportSubject.getValue();
        if (currentSelection) {
            let confirmationPromise: Promise<boolean>;
            if (project) {
                confirmationPromise = ModalUtils.createOverwriteProjectImportConfirmation(
                    this.modalSvc,
                    this.translate
                ).isConfirmedPromise;
            } else {
                confirmationPromise = ModalUtils.createCancelProjectImportConfirmation(
                    this.modalSvc,
                    this.translate
                ).isConfirmedPromise;
            }
            if (await confirmationPromise) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    public selectProjectForImport(project: IListableProject): void {
        this.projectToImportSubject.next(project);
    }

    public async changeListedProjectVersion(
        previousProjectVersionId: number,
        selectedProjectVersionId: number
    ): Promise<void> {
        const currentSelection = this.projectToImportSubject.getValue();
        if (currentSelection?.id === previousProjectVersionId) {
            const { isConfirmedPromise } = ModalUtils.createOverwriteProjectImportConfirmation(
                this.modalSvc,
                this.translate
            );
            if (await isConfirmedPromise) {
                const selectedProject = await this.getListableProjectById(selectedProjectVersionId);
                this.listedProjectVersionSubject.next({
                    previousProjectId: previousProjectVersionId,
                    newProject: selectedProject
                });
                this.projectToImportSubject.next(selectedProject);
            }
        } else {
            const selectedProject = await this.getListableProjectById(selectedProjectVersionId);
            this.listedProjectVersionSubject.next({
                previousProjectId: previousProjectVersionId,
                newProject: selectedProject
            });
        }
    }

    public async getUnsupportedPartnersPerBusinessType(): Promise<Array<IUnsupportedPartnersPerBusinessType>> {
        const currentUnsupportedPartnersPerBusinessType = this.unsupportedPartnersPerBusinessTypeSubject.getValue();
        if (currentUnsupportedPartnersPerBusinessType) {
            return currentUnsupportedPartnersPerBusinessType;
        }

        const unsupportedPartnersPerBusinessType = await this.httpService.get<
            Array<IUnsupportedPartnersPerBusinessType>
        >(null, ROUTE_ID.UNSUPPORTED_PARTNERS_PER_BUSINESS_TYPE);
        this.unsupportedPartnersPerBusinessTypeSubject.next(unsupportedPartnersPerBusinessType);

        return unsupportedPartnersPerBusinessType;
    }

    public async changeProjectOwnerToCurrentUser(id: number): Promise<IProject> {
        const project = await this.httpService.post<IProject>(ROUTE_ID.CHANGE_PROJECT_OWNER, { id });

        this.updateProjectInCache(project?.id, project);
        return project;
    }

    public updateProjectInCache(
        projectId: number,
        project: IProject,
        statusCode: HttpStatusCode = HttpStatusCode.Ok
    ): void {
        if (isNullOrUndefined(projectId) || isNaN(projectId)) {
            return;
        }

        const cachedProjectsMap = this.cachedProjectsSubject.getValue();
        const cachedProjectData = cachedProjectsMap.get(projectId);
        const mutations = calculateMutations(cachedProjectData?.project, project);

        if (mutations.length || cachedProjectData?.statusCode !== statusCode) {
            cachedProjectsMap.set(projectId, { project, statusCode });

            if (mutations.findIndex((mutation) => mutation.path.includes('/permissions')) > -1 || !project) {
                this.authorizationSvc.setOpenedProjectPermissionMap(project); // TODO: we need projectId in the permissions subject as well
            }

            this.cachedProjectsSubject.next(cachedProjectsMap);
        }
    }
}
