import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IsDateBeforePipe } from '../../common-utils/pipes/is-date-before.pipe';
import { ROUTE_ID } from '../../constants/api-routes';
import { HttpService } from '../../core/services/http.service';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { NewsStatus } from '../models/enums/newsStatus';
import { INews } from '../models/INews';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    public readonly hasUnreadNews$: Observable<boolean>;
    public readonly news$: Observable<Array<INews>>;

    private readonly hasUnreadNewsSubject = new BehaviorSubject(false);
    private readonly newsSubject = new BehaviorSubject<Array<INews>>(null);

    constructor(
        private httpService: HttpService,
        private storageService: LocalStorageService
    ) {
        this.hasUnreadNews$ = this.hasUnreadNewsSubject.asObservable();
        this.news$ = this.newsSubject.asObservable();
    }

    public updateUnreadNewsStatus(status: boolean): void {
        this.storageService.set(LOCAL_STORAGE_KEY.LAST_READ_NEWS, new Date());
        this.hasUnreadNewsSubject.next(status);
    }

    public async getNews(): Promise<Array<INews>> {
        const oldNews = this.newsSubject.getValue();
        if (!oldNews) {
            const news = await this.httpService.get<Array<INews>>(null, ROUTE_ID.GET_NEWS);
            this.newsSubject.next(news);
            if (news?.length) {
                const publishedNews = news.filter((news: INews) => news.status === NewsStatus.Published);
                if (!!publishedNews?.length) {
                    const hasUnreadNews = new IsDateBeforePipe().transform(
                        this.storageService.get(LOCAL_STORAGE_KEY.LAST_READ_NEWS),
                        publishedNews[0].publishedAt
                    );
                    this.hasUnreadNewsSubject.next(hasUnreadNews);
                }
            }
            return news;
        }

        return oldNews;
    }
}
