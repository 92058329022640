import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from 'sfx-commons';
import { SafeAny } from '../../constants';

@Pipe({
    name: 'noNulls'
})
export class NoNullsPipe implements PipeTransform {
    public transform(items: Array<SafeAny>): Array<SafeAny> {
        if (!items?.length) {
            return items;
        }
        return items.filter((item) => !isNullOrUndefined(item));
    }
}
