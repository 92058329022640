import { ActivatedRouteSnapshot, UrlMatchResult, UrlSegment } from '@angular/router';
import {
    COLLATERAL_MODAL_PATHS,
    DOCUMENT_VERSIONS_MODAL_PATH,
    EDIT_PROJECT_PATHS,
    LANGUAGE_FILE_NAME_SUFFIX,
    SUPPORTED_LANGUAGE
} from '../../constants';
import { ProjectStatus } from '../../projects/models/enums/projectStatus';
import { IProject } from '../../projects/models/IProject';
import { IApplicationUser } from '../models/IApplicationUser';
import { AppInitializerService } from '../services/app-initializer.service';
import { ProjectUtils } from './project.utils';

export class RouterUtils {
    private static readonly ROUTES_WITH_OPTIONAL_PARAMS = [];
    private static readonly URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM = {};

    public static canUserAccessTeamSelling(
        user: IApplicationUser,
        ownerEmail: string,
        isTeamSellingEnabledForProject: boolean,
        hasTeamSellingAccessPermission: boolean
    ): boolean {
        return !!(
            user?.tenantSettings?.isTeamSellingEnabled &&
            ownerEmail &&
            isTeamSellingEnabledForProject &&
            hasTeamSellingAccessPermission
        );
    }

    public static getFullPathFromSnapshot(route: ActivatedRouteSnapshot, childContent = ''): string {
        let currentSnapshot;

        if (!route.children.length) {
            currentSnapshot = route.pathFromRoot[route.pathFromRoot.length - 1];
        } else {
            currentSnapshot = route;
        }

        if (!currentSnapshot || !currentSnapshot.url || !currentSnapshot.url.length) {
            return '/' + childContent;
        }

        let prefix = '';
        let suffix = '';
        let content = '';

        if (currentSnapshot.outlet && currentSnapshot.outlet !== 'primary') {
            prefix = '(';
            suffix = ')';
            content = currentSnapshot.outlet + ':';
        }
        content += currentSnapshot.url.map((segment) => segment.path).join('/') + (childContent ? '/' : '');
        const url = prefix + content + childContent + suffix;
        if (currentSnapshot.parent) {
            return this.getFullPathFromSnapshot(currentSnapshot.parent, url);
        } else {
            return '/' + childContent;
        }
    }

    public static generateRoutesAndUrlPropNamesPerOptionalParams(): void {
        if (RouterUtils.ROUTES_WITH_OPTIONAL_PARAMS?.length) {
            return;
        }

        RouterUtils.ROUTES_WITH_OPTIONAL_PARAMS.splice(0);
        Object.keys(RouterUtils.URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM).map(
            (key) => delete RouterUtils.URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM[key]
        );

        Object.keys(AppInitializerService.asyncRoutes).map((language) => {
            const asyncRoutesPerLanguage = AppInitializerService.asyncRoutes[language];
            RouterUtils.ROUTES_WITH_OPTIONAL_PARAMS.push(asyncRoutesPerLanguage[COLLATERAL_MODAL_PATHS.COLLATERAL]);
            RouterUtils.ROUTES_WITH_OPTIONAL_PARAMS.push(asyncRoutesPerLanguage[EDIT_PROJECT_PATHS.DOCUMENTS]);
            RouterUtils.ROUTES_WITH_OPTIONAL_PARAMS.push(
                asyncRoutesPerLanguage[EDIT_PROJECT_PATHS.OFFER_DETAILS_DRAWER]
            );
            RouterUtils.ROUTES_WITH_OPTIONAL_PARAMS.push(asyncRoutesPerLanguage[DOCUMENT_VERSIONS_MODAL_PATH]);

            RouterUtils.URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM[
                asyncRoutesPerLanguage[COLLATERAL_MODAL_PATHS.COLLATERAL]
            ] = ['index'];
            RouterUtils.URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM[asyncRoutesPerLanguage[EDIT_PROJECT_PATHS.DOCUMENTS]] =
                ['documentId'];
            RouterUtils.URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM[
                asyncRoutesPerLanguage[EDIT_PROJECT_PATHS.OFFER_DETAILS_DRAWER]
            ] = ['lenderId', 'offerId', 'swimlaneIndex'];
            RouterUtils.URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM[asyncRoutesPerLanguage[DOCUMENT_VERSIONS_MODAL_PATH]] =
                ['docVerId'];
        });
    }

    public static getLanguageFromUri(): string {
        const pathNameLanguage = window.location.pathname.split('/')[1];

        if (
            Object.keys(LANGUAGE_FILE_NAME_SUFFIX).findIndex(
                (lang) => pathNameLanguage === LANGUAGE_FILE_NAME_SUFFIX[lang]
            ) > -1
        ) {
            return pathNameLanguage;
        } else {
            return LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.EN];
        }
    }

    public static isAllowedToAccessProjectOffersPage(project: IProject): boolean {
        return (
            !!project?.submittedToLendersDate &&
            ProjectUtils.isProjectStatusAfter(project.projectStatus, ProjectStatus.SubmittedToLenders)
        );
    }

    public static getDefaultEditProjectRelativePath(project: IProject): string {
        const isAllowedToAccessProjectOffersPage = RouterUtils.isAllowedToAccessProjectOffersPage(project);
        return isAllowedToAccessProjectOffersPage ? EDIT_PROJECT_PATHS.OFFERS : EDIT_PROJECT_PATHS.REQUEST;
    }

    public static routeWithParamsMatcher(segments: UrlSegment[]): UrlMatchResult | null {
        RouterUtils.generateRoutesAndUrlPropNamesPerOptionalParams();

        if (segments.length > 0 && RouterUtils.ROUTES_WITH_OPTIONAL_PARAMS.indexOf(segments[0].path) > -1) {
            if (segments.length === 1) {
                return {
                    consumed: segments,
                    posParams: {}
                };
            }
            const namesOfUrlParams = RouterUtils.URL_PROPERTY_ID_NAMES_PER_OPTIONAL_PARAM[segments[0].path];
            const posParams = {};
            namesOfUrlParams.map((item, index) => {
                if (index + 1 < segments.length) {
                    posParams[item] = segments[index + 1];
                }
            });

            if (segments.length >= 2) {
                return {
                    consumed: segments,
                    posParams
                };
            }

            return null;
        }
        return null;
    }
}
