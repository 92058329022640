/* eslint-disable @typescript-eslint/no-explicit-any */
export class EnumUtils {
    public static isElementBefore(
        enumeration: any,
        currentItem: any,
        determiningItem: any,
        doIncludeSelf = true
    ): boolean {
        const keys = Object.keys(enumeration);
        const currentItemIndex = keys.indexOf(currentItem);
        const determiningItemIndex = keys.indexOf(determiningItem);

        if (currentItemIndex < determiningItemIndex) {
            return true;
        } else if (currentItemIndex === determiningItemIndex && doIncludeSelf) {
            return true;
        }

        return false;
    }

    public static isElementAfter(
        enumeration: any,
        currentItem: any,
        determiningItem: any,
        doIncludeSelf = true
    ): boolean {
        const keys = Object.keys(enumeration);
        const currentItemIndex = keys.indexOf(currentItem);
        const determiningItemIndex = keys.indexOf(determiningItem);

        if (currentItemIndex > determiningItemIndex) {
            return true;
        } else if (currentItemIndex === determiningItemIndex && doIncludeSelf) {
            return true;
        }

        return false;
    }
}
