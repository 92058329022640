import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
    AuthConfig,
    OAuthErrorEvent,
    OAuthEvent,
    OAuthInfoEvent,
    OAuthService,
    OAuthSuccessEvent
} from 'angular-oauth2-oidc';
import * as jsonPatch from 'fast-json-patch';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { calculateMutations, isNullOrUndefined } from 'sfx-commons';
import { environment } from '../../../environments/environment';
import {
    AuthServiceConfigType,
    IdentityProvider,
    LegacyIdentityProvider,
    LOGIN_QUERY,
    LOGIN_QUERY_LOGOUT_REASON_MAP,
    LOGOUT_REASON,
    MAIN_ROUTES
} from '../../constants';
import { ROUTE_ID } from '../../constants/api-routes';
import { IdentityProviderRefreshTokenConfigMap } from '../../constants/identity-provider-refresh-token-config-map';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { IRoleAndPermissionShortcuts } from '../../core/models/IRoleAndPermissionShortcuts';
import { HttpService } from '../../core/services/http.service';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { LogoutImplicationsService } from '../../core/services/logout-implications.service';
import { RouterService } from '../../core/services/router.service';
import { RouterUtils } from '../../core/utils/router.utils';
import { getDefaultSSOConfigs, getIdentityProvider } from '../../core/utils/sso-config.utils';
import { DiagnosticsService } from '../../diagnostics/services/diagnostics.service';
import { ALREADY_CONFIRMED_LEAVING_PROJECT_NAVIGATION_EXTRAS } from '../edit-project/constants';
import { AuthorizationService } from './authorization.service';

export interface IAuthStatus {
    isAuthenticated: boolean;
    isAuthorized: boolean;
    tokenExpiration: number;
    isBackofficeUser: boolean;
    error: string;
    activeProvider: IdentityProvider;
}

const configs: { [key in IdentityProvider]?: AuthConfig } = getDefaultSSOConfigs();

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService implements IRoleAndPermissionShortcuts {
    public authStatus: Observable<IAuthStatus>;
    public readonly currentUser$: Observable<IApplicationUser>;
    public hypoDossierToken: Observable<string>;

    private readonly EMPTY_AUTH_STATUS_OBJ: IAuthStatus = {
        isAuthenticated: false,
        isAuthorized: false,
        tokenExpiration: 0,
        isBackofficeUser: false,
        error: '',
        activeProvider: null
    };

    private activeIdentityProvider: IdentityProvider;
    private refetchTokenTimeout: ReturnType<typeof setTimeout>;
    private readonly authStatusSubject: BehaviorSubject<IAuthStatus>;
    private readonly currentUserSubject: BehaviorSubject<IApplicationUser>;
    private readonly hypoDossierTokenSubject: BehaviorSubject<string>;

    constructor(
        private readonly authorizationSvc: AuthorizationService,
        private readonly diagnosticsSvc: DiagnosticsService,
        private readonly httpSvc: HttpService,
        private readonly oAuthService: OAuthService,
        private readonly localStorageSvc: LocalStorageService,
        private readonly logoutImplicationsSvc: LogoutImplicationsService,
        private readonly routerSvc: RouterService,
        private readonly translate: TranslateService
    ) {
        this.authStatusSubject = new BehaviorSubject<IAuthStatus>({
            ...this.EMPTY_AUTH_STATUS_OBJ
        });
        this.authStatus = this.authStatusSubject.asObservable();
        this.currentUserSubject = new BehaviorSubject<IApplicationUser>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();

        this.hypoDossierTokenSubject = new BehaviorSubject<string>(null);
        this.hypoDossierToken = this.hypoDossierTokenSubject.asObservable();

        this.oAuthService.events.subscribe(async (event: OAuthEvent) => {
            if (window.location.pathname.includes('iss')) {
                const pathName = window.location.pathname.split('&iss')[0];
                window.history.pushState('', window.document.title, pathName);
            }

            switch (event.constructor) {
                case OAuthSuccessEvent:
                    switch (event.type) {
                        case 'discovery_document_loaded':
                        case 'token_received':
                            this.cancelRefetchTokenTimeout();
                            this.updateAuthStatus();
                            break;
                        case 'token_refreshed':
                            this.cancelRefetchTokenTimeout();
                            if (!this.getAuthStatus().isAuthorized) {
                                await this.loginToDfs();
                            }
                            this.updateAuthStatus();
                            break;
                        default:
                            break;
                    }
                    break;
                case OAuthInfoEvent:
                    switch (event.type) {
                        case 'session_changed':
                            await this.loginToDfs();
                            this.updateAuthStatus();
                            break;
                        case 'session_error':
                            this.updateAuthStatus(null, event.type);
                            break;
                        case 'session_terminated':
                        case 'logout':
                            console.warn(event);
                            // logout event is called by oauthSvc logOut method. logOut method can be called internally by oauthservice with revokeTokenAndLogout method and also in case session has changed (not sure how to reproduce this)
                            if (this.getAuthStatus().isAuthenticated) {
                                // but oauth failed to refresh the token. In that case `session_terminated` is also triggered.
                                // Consider reacting to `session_terminated` event and navigate to Logout only in that case.
                                void this.routerSvc.navigateToRoute([MAIN_ROUTES.LOGOUT]);
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                case OAuthErrorEvent:
                    switch (event.type) {
                        case 'discovery_document_validation_error':
                        case 'code_error':
                        case 'discovery_document_load_error':
                        case 'jwks_load_error':
                        case 'token_validation_error':
                            this.updateAuthStatus(null, event.type);
                            break;
                        case 'token_error':
                        case 'token_refresh_error':
                            console.error(event);
                            if (this.doesIdentityProviderSupportRefreshToken(this.activeIdentityProvider)) {
                                this.reattemptRefetchToken();
                            } else {
                                this.updateAuthStatus(null, event.type);
                            }
                            break;
                        default:
                            // TODO: validate if correct not to do anything
                            // this.updateAuthStatus(null, event.type);
                            break;
                    }
                    break;
            }
        });
    }

    public async init(): Promise<void> {
        const defaultIdentityProvider =
            window.location.host === environment.originsPerUserType.Broker
                ? IdentityProvider.DfsIdentityServer
                : IdentityProvider.SwissLife;

        const setProvider: IdentityProvider =
            getIdentityProvider(
                this.localStorageSvc.get<LegacyIdentityProvider | IdentityProvider>(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID)
            ) || defaultIdentityProvider;

        if (!!IdentityProvider[setProvider] && !!configs[setProvider]) {
            this.activeIdentityProvider = setProvider;
            this.configureAuthService();
            await this.oAuthService.loadDiscoveryDocumentAndTryLogin();
        } else {
            this.localStorageSvc.remove(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID);
            await this.routerSvc.navigateToLogout();
        }
    }

    public get isAdmin(): boolean {
        return this.authorizationSvc.isAdmin;
    }

    public get isSfxAdmin(): boolean {
        return this.authorizationSvc.isSfxAdmin;
    }

    public get isSupporter(): boolean {
        return this.authorizationSvc.isSupporter;
    }

    public get isUser(): boolean {
        return this.authorizationSvc.isUser;
    }

    public get isTrainee(): boolean {
        return this.authorizationSvc.isTrainee;
    }

    public get currentIdentityProvider(): IdentityProvider {
        return this.activeIdentityProvider;
    }

    public getAuthStatus(): IAuthStatus {
        return this.authStatusSubject.getValue();
    }

    public hasValidAccessToken(): boolean {
        return (
            this.hasValidAccessTokenIncludingClockSkew() && +this.oAuthService.getAccessTokenExpiration() > Date.now()
        );
    }

    public hasValidAccessTokenIncludingClockSkew(): boolean {
        return this.oAuthService.hasValidAccessToken();
    }

    public getBearerToken(): string {
        const token = this.getAccessToken();
        if (token) {
            return `Bearer ${token}`;
        } else {
            return token;
        }
    }

    public getAccessToken(): string {
        return this.oAuthService.getAccessToken();
    }

    public async getCurrentUser(): Promise<IApplicationUser> {
        let user = this.getCurrentCachedUser();
        if (!user) {
            try {
                user = await this.httpSvc.get<IApplicationUser>(null, ROUTE_ID.CURRENT_USER);
            } catch (e) {
                console.error(e);
                throw e;
            }
            this.setCurrentUser(user);
            this.updateAuthStatus(user);
        }

        return user;
    }

    public getCurrentCachedUser(): IApplicationUser {
        return this.currentUserSubject.getValue();
    }

    public async obtainHypoDossierToken(projectId: number): Promise<string> {
        const { token } = await this.httpSvc.post<{ token: string }>(
            ROUTE_ID.OBTAIN_HYPO_DOSSIER_TOKEN,
            { projectId },
            null,
            null,
            null,
            {}
        );
        this.hypoDossierTokenSubject.next(token);

        return token;
    }

    public cancelObtainHypoDossierTokenRequest(): void {
        this.httpSvc.cancelRequest(ROUTE_ID.OBTAIN_HYPO_DOSSIER_TOKEN, 'POST');
    }

    public resetHypoDossierTokenIfExists(): void {
        if (!!this.hypoDossierTokenSubject.getValue()) {
            this.hypoDossierTokenSubject.next(null);
        }
    }

    public async updateCurrentUser(
        mutations: Array<jsonPatch.Operation>,
        formGroup: FormGroup
    ): Promise<IApplicationUser> {
        const user = await this.httpSvc.jsonPatch<IApplicationUser>(
            formGroup,
            ROUTE_ID.CURRENT_USER,
            null,
            null,
            mutations
        );
        this.setCurrentUser(user);
        this.updateAuthStatus(user);

        return user;
    }

    public async login(identityProvider: IdentityProvider): Promise<void> {
        if (this.hasValidAccessToken()) {
            await this.routerSvc.navigateToRoute([MAIN_ROUTES.LOGGING_IN]);
            return;
        }

        this.activeIdentityProvider = identityProvider;
        this.localStorageSvc.set(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID, this.activeIdentityProvider);
        this.configureAuthService();

        await this.oAuthService.loadDiscoveryDocumentAndLogin();
    }

    public async loginToDfs(): Promise<IApplicationUser> {
        return await this.httpSvc.post<IApplicationUser>(ROUTE_ID.LOGIN);
        // this.setCurrentUser(user);
        // this.mapUserToAuthStatus(user);
    }

    public async logout(reason: LOGOUT_REASON, shouldNotRedirectToSSOLogout: boolean): Promise<void> {
        void this.logoutFromDfs();

        const configOverrides: Partial<AuthConfig> = {
            revocationEndpoint: this.oAuthService.revocationEndpoint
        };

        if (!shouldNotRedirectToSSOLogout) {
            const translatedLoginRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGIN]);
            const queryParams = !!reason ? `?${LOGIN_QUERY_LOGOUT_REASON_MAP[reason]}=${reason}` : '';
            configOverrides.postLogoutRedirectUri = `${window.location.origin}/${this.translate.currentLang}/${translatedLoginRoute}${queryParams}`;
            configOverrides.logoutUrl = this.oAuthService.logoutUrl;
        }
        this.configureAuthService(AuthServiceConfigType.LOGOUT, configOverrides);

        this.setCurrentUser(undefined);
        this.updateAuthStatus(null, '');
        this.logoutImplicationsSvc.clearCache();

        if (reason !== LOGOUT_REASON.SESSION_PROLONG) {
            this.localStorageSvc.remove(LOCAL_STORAGE_KEY.SSO_ACTIVE_ID);
        }

        if (
            this.doesIdentityProviderSupportRefreshToken(this.activeIdentityProvider) &&
            !!this.oAuthService.revocationEndpoint
        ) {
            try {
                await this.oAuthService.revokeTokenAndLogout(shouldNotRedirectToSSOLogout);
            } catch (e) {
                console.error(e);
                this.oAuthService.logOut(false);
            }
        } else {
            this.oAuthService.logOut(shouldNotRedirectToSSOLogout);
        }
    }

    public async markKey4TrainingAsCompleted(): Promise<void> {
        await this.httpSvc.post<void>(ROUTE_ID.MARK_KEY4_TRAINING_AS_COMPLETED);
    }

    public parseToken(jwtToken: string): JwtPayload {
        return jwtDecode(jwtToken);
    }

    public async prolongSession(): Promise<void> {
        await this.routerSvc.navigateToLogout({
            queryParams: {
                [LOGIN_QUERY_LOGOUT_REASON_MAP[LOGOUT_REASON.SESSION_PROLONG]]: LOGOUT_REASON.SESSION_PROLONG,
                [LOGIN_QUERY.RETURN_URL]: this.routerSvc.getValidReturnUrl(this.routerSvc.currentUrl)
            },
            ...ALREADY_CONFIRMED_LEAVING_PROJECT_NAVIGATION_EXTRAS
        });
    }

    public doesIdentityProviderSupportRefreshToken(identityProvider: IdentityProvider): boolean {
        return !!IdentityProviderRefreshTokenConfigMap[identityProvider]?.isEnabled;
    }

    private configureAuthService(
        configType: AuthServiceConfigType = AuthServiceConfigType.LOGIN,
        configOverrides: Partial<AuthConfig> = {}
    ): void {
        if (configType === AuthServiceConfigType.LOGOUT) {
            this.oAuthService.stopAutomaticRefresh();
        }
        this.oAuthService.configure({
            ...configs[this.activeIdentityProvider],
            ...this.getSSOLanguageBasedConfigOverrides(),
            ...configOverrides
        });
        if (
            configType === AuthServiceConfigType.LOGIN &&
            this.doesIdentityProviderSupportRefreshToken(this.activeIdentityProvider)
        ) {
            this.oAuthService.setupAutomaticSilentRefresh();
        }
    }

    private getSSOLanguageBasedConfigOverrides(): Partial<AuthConfig> {
        const configOverrides: Partial<AuthConfig> = {};
        const translateRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGGING_IN], null, true);
        configOverrides.redirectUri = `${window.location.origin}${translateRoute}`;

        if (this.activeIdentityProvider === IdentityProvider.DfsIdentityServer) {
            configOverrides.customQueryParams = { language: RouterUtils.getLanguageFromUri().toLowerCase() };
        }
        return configOverrides;
    }

    private async logoutFromDfs(): Promise<void> {
        try {
            await this.httpSvc.post<IApplicationUser>(ROUTE_ID.LOGOUT);
        } catch {
            // exception occurs when the token already expired, so there is no security threat
        }
    }

    private cancelRefetchTokenTimeout(): void {
        clearTimeout(this.refetchTokenTimeout);
    }

    private reattemptRefetchToken(): void {
        this.cancelRefetchTokenTimeout();
        this.refetchTokenTimeout = setTimeout(async () => {
            if (this.hasValidAccessToken() && this.oAuthService.getRefreshToken()) {
                await this.oAuthService.refreshToken();
            }
        }, 5000);
    }

    private updateAuthStatus(user?: IApplicationUser, errorKey?: string): void {
        let authStatus: IAuthStatus;
        const tokenExpiration = +this.oAuthService.getAccessTokenExpiration();
        if (!isNullOrUndefined(errorKey)) {
            authStatus = {
                ...this.EMPTY_AUTH_STATUS_OBJ,
                error: errorKey.length ? `auth.errors.${errorKey}` : null
            };
        } else {
            const currentUser = user || this.getCurrentCachedUser();
            const isAuthenticated = this.hasValidAccessToken();
            const isAuthorized = isAuthenticated && !!currentUser;

            authStatus = {
                isAuthenticated,
                isAuthorized,
                tokenExpiration: isAuthenticated ? tokenExpiration : 0,
                activeProvider: isAuthenticated ? this.activeIdentityProvider : null,
                isBackofficeUser: isAuthorized ? currentUser.isInternalUser : false,
                error: null
            };
        }

        this.notifyAuthStatusChangeIfNecessary(authStatus);
    }

    private notifyAuthStatusChangeIfNecessary(authStatus: IAuthStatus): void {
        const cachedAuthStatus: IAuthStatus = this.authStatusSubject.getValue();
        const mutations = calculateMutations(authStatus, cachedAuthStatus);
        if (mutations.length) {
            this.diagnosticsSvc.log({
                key: authStatus.error ? 'updateAuthWithError' : 'updateAuthStatus',
                value: `authStatus: ${JSON.stringify(authStatus)};${
                    authStatus.error
                        ? ''
                        : ` tokenExpiration: ${authStatus.tokenExpiration ? new Date(authStatus.tokenExpiration).toString() : authStatus.tokenExpiration}`
                }`
            });
            this.authStatusSubject.next(authStatus);
        }
    }

    private setCurrentUser(user: IApplicationUser): void {
        this.authorizationSvc.setRoleAndGlobalPermissionMaps(user);
        this.currentUserSubject.next(user);
    }
}
