<div
    class="mad-navigation-bar"
    [class.mad-navigation-bar--compact]="isCompact"
    *ngIf="currentUser"
>
    <div class="mad-navigation-bar__body">
        <div class="mad-navigation-bar__logo-wrapper">
            <a
                class="mad-navgation-bar__logo"
                [routerLink]="HOME_NAV_ITEM.routeSubItems | translateRoute: HOME_NAV_ITEM.routeSubItemParams"
                [routerLinkActive]="'pointer-events--none'"
                [routerLinkActiveOptions]="HOME_NAV_ITEM.routerLinkActiveOptions"
            >
                <sfx-logo [path]="isCompact ? 'sfx.svg#sfx' : 'swissfex.svg#swissfex'"></sfx-logo>
            </a>
        </div>
        <app-sidebar-item
            [navItem]="HOME_NAV_ITEM"
            [isCollapsed]="isCompact"
            class="mad-navigation-bar__home"
        ></app-sidebar-item>

        <ng-container
            *ngIf="PROJECT_SPECIFIC_NAV_ITEMS | onlyHavingTruthyProperty: 'isVisible' as visibleProjectSpecificItems"
        >
            <ng-container *ngIf="visibleProjectSpecificItems?.length">
                <sfx-section-separator extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>
                <div class="mad-navigation-bar__group">
                    <ng-container *ngFor="let navItem of visibleProjectSpecificItems">
                        <app-sidebar-item
                            *ngIf="navItem.isVisible"
                            [navItem]="navItem"
                            [isCollapsed]="isCompact"
                            [isNavigationResolverInProgress]="isNavigationGuardOrResolverInProgress"
                        >
                        </app-sidebar-item>
                    </ng-container>
                </div>
                <sfx-section-separator extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>
            </ng-container>
        </ng-container>
        <div class="mad-spacer"></div>
        <sfx-section-separator extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>
        <app-sidebar-item
            *ngFor="let navItem of BOTTOM_NAV_ITEMS"
            [navItem]="navItem"
            [isCollapsed]="isCompact"
            [isNavigationResolverInProgress]="isNavigationGuardOrResolverInProgress"
        >
        </app-sidebar-item>
        <app-sidebar-item
            [navItem]="logoutItem"
            [isCollapsed]="isCompact"
            [isNavigationResolverInProgress]="isNavigationGuardOrResolverInProgress"
        >
        </app-sidebar-item>
        <sfx-section-separator extraClasses="mad-margin-horizontal-n8"></sfx-section-separator>
        <app-sidebar-item
            [navItem]="privacyModeItem"
            [isCollapsed]="isCompact"
            [isNavigationResolverInProgress]="isNavigationGuardOrResolverInProgress"
        >
        </app-sidebar-item>
    </div>
</div>
