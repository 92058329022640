import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '../../constants';

@Pipe({
    name: 'onlyHavingTruthyProperty'
})
export class OnlyHavingTruthyPropertyPipe implements PipeTransform {
    public transform(items: Array<SafeAny>, truthyProperty: string): SafeAny {
        if (!items || !truthyProperty) {
            return items;
        }

        return items.filter((item) => item[truthyProperty]);
    }
}
