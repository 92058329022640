import { Injectable, OnDestroy } from '@angular/core';
import * as DateFns from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DiagnosticLog } from '../../constants/types';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class DiagnosticsService implements OnDestroy {
    public logs: Observable<Array<DiagnosticLog>>;

    private readonly key: string;

    private isActive = false;
    private logSubject: BehaviorSubject<Array<DiagnosticLog>> = new BehaviorSubject([]);

    constructor(private storageSvc: LocalStorageService) {
        this.key = environment.diagnosticLogKeyBase ? btoa(environment.diagnosticLogKeyBase) : null;
        this.isActive = this.tryActivateService(null);
        this.logs = this.logSubject.asObservable();

        this.populateWithStoredValuesIfNeeded();
    }

    public ngOnDestroy(): void {
        const logs = this.logSubject.getValue();
        if (this.isActive && logs.length > 0) {
            this.storageSvc.set(LOCAL_STORAGE_KEY.DIAGNOSTIC_LOGS, logs);
        }
    }

    public disable(): void {
        this.clearStorage();
        this.isActive = false;
    }

    public get isActivated(): boolean {
        return this.isActive;
    }

    public tryActivateService(key: string): boolean {
        const areStorageTicksValid = !!this.validLogsEnabledTicks;
        if (!areStorageTicksValid) {
            this.clearStorage();
        }

        this.isActive = (!!this.key && !!key && key === this.key) || !!areStorageTicksValid;

        if (this.isActive && !areStorageTicksValid) {
            this.storageSvc.set(LOCAL_STORAGE_KEY.DIAGNOSTIC_LOGS_ENABLED_DATE, Date.now());
        }

        return this.isActive;
    }

    public log(item: DiagnosticLog) {
        if (!this.isActive) {
            return;
        }

        let array = this.logSubject.getValue();
        if (!array) {
            array = [];
        }
        array.splice(0, 0, { ...item, date: new Date().toISOString() as unknown as Date });
        this.storageSvc.set(LOCAL_STORAGE_KEY.DIAGNOSTIC_LOGS, array);
        this.logSubject.next(array);
    }

    public getCurrentLogs(): Array<{ key: string; value: string }> {
        return this.logSubject.getValue();
    }

    private clearStorage(): void {
        this.storageSvc.remove(LOCAL_STORAGE_KEY.DIAGNOSTIC_LOGS_ENABLED_DATE);
        this.storageSvc.remove(LOCAL_STORAGE_KEY.DIAGNOSTIC_LOGS);
    }

    private populateWithStoredValuesIfNeeded(): void {
        if (this.key && this.isActive) {
            const oldValue = this.storageSvc.get<Array<DiagnosticLog>>(LOCAL_STORAGE_KEY.DIAGNOSTIC_LOGS);
            if (oldValue && oldValue.length) {
                this.logSubject.next(oldValue);
            }
        }
    }

    private get validLogsEnabledTicks(): number {
        const storageTicks = this.storageSvc.get<number>(LOCAL_STORAGE_KEY.DIAGNOSTIC_LOGS_ENABLED_DATE);
        const areStorageTicksValid =
            storageTicks && DateFns.differenceInMinutes(new Date(), new Date(storageTicks)) < 5;

        return areStorageTicksValid ? storageTicks : null;
    }
}
