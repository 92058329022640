import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IsActiveMatchOptions, Params, QueryParamsHandling, RouterLink, RouterLinkActive } from '@angular/router';
import { IconProp } from '@fortawesome/angular-fontawesome/types';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { TranslatePipe } from '@ngx-translate/core';
import { BadgeComponent, IconComponent } from 'sfx-commons';
import { NoNullsPipe } from '../../common-utils/pipes/no-nulls.pipe';
import { TranslateOutletPipe } from '../../common-utils/pipes/translate-outlet.pipe';
import { TranslateRoutePipe } from '../../common-utils/pipes/translate-route.pipe';
import { SafeAny } from '../../constants';

export enum SidebarItemHighlightType {
    Error = 'Error',
    Warning = 'Warning'
}

export interface ISidebarItem {
    routeSubItems?: Array<string | number>;
    routeSubItemParams?: Array<string | number>;
    routerLinkActive?: string[] | string;
    routerLinkActiveOptions?: { exact: boolean } | IsActiveMatchOptions;
    isAbsolute?: boolean;
    key: string;
    icon: IconProp;
    collapsedIcon?: IconProp;
    outstandingNotifications?: number;
    hasSeparatorAfter?: boolean;
    outlet?: { [key: string]: string };
    outletParams?: Array<number>;
    queryParams?: Params | null | undefined;
    queryParamsHandling?: QueryParamsHandling;
    isDisabled?: boolean;
    isVisible: boolean;
    highlightType?: SidebarItemHighlightType;
    extraClasses?: string;
    hideCollapsed?: boolean;
    onClick?: SafeAny;
    isActive?: boolean;
    isInverted?: boolean;
}

@Component({
    selector: 'app-sidebar-item',
    templateUrl: './sidebar-item.component.html',
    styleUrls: ['./sidebar-item.component.scss'],
    imports: [
        NgIf,
        NgClass,
        RouterLinkActive,
        RouterLink,
        NgTemplateOutlet,
        TranslatePipe,
        NoNullsPipe,
        TranslateOutletPipe,
        TranslateRoutePipe,
        IconComponent,
        BadgeComponent
    ]
})
export class SidebarItemComponent {
    @Input({ required: true })
    public navItem: ISidebarItem;
    @Input()
    public isCollapsed = false;
    @Input()
    public isNavigationResolverInProgress = false;

    public readonly SidebarItemHighlightType = SidebarItemHighlightType;
    public readonly exclamationIcon = faExclamation;

    public doNothing = () => null;
}
