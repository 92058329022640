import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription } from 'rxjs';
import {
    calculateMutations,
    ISelectOption,
    ModalUtils as SfxModalUtils,
    tryToUnsubscribeFromSubscription
} from 'sfx-commons';
import { IGNORED_PROJECT_MUTATION_PATHS, OUTLET_NAMES, PROJECT_PAGE_URL_SUBITEMS_TEMPLATE } from '../../constants';
import { BusinessType } from '../../projects/models/enums/businessType';
import { ProjectPermission } from '../../projects/models/enums/projectPermission';
import { ProjectStatus } from '../../projects/models/enums/projectStatus';
import { IInstitution } from '../../projects/models/IInstitution';
import { IInstitutionsData } from '../../projects/models/IInstitutionsData';
import { IProject } from '../../projects/models/IProject';
import { IProjectLeaveAction } from '../../projects/models/IProjectLeaveAction';
import { IProjectVersion } from '../../projects/models/IProjectVersion';
import { IUnsupportedPartnersPerBusinessType } from '../../projects/models/IUnsupportedPartnersPerBusinessType';
import { AuthorizationService } from '../../projects/services/authorization.service';
import { DocumentsService } from '../../projects/services/documents.service';
import { ProjectsService } from '../../projects/services/projects.service';
import { RouterService } from '../services/router.service';
import { DocumentUtils } from './document.utils';
import { EnumUtils } from './enum.utils';
import { ModalUtils } from './modal.utils';
import { RouterUtils } from './router.utils';

export class ProjectUtils {
    public static isProjectEditable(project: IProject | IProjectVersion): boolean {
        return project && !project.isFrozen;
    }

    public static conditionallyDisableFormGroupsForProject(
        isProjectEditable: boolean,
        shortModelFormGroup: UntypedFormGroup | UntypedFormArray,
        extendedModelFormGroup?: UntypedFormGroup | UntypedFormArray
    ): void {
        const disableOptions = { emitEvent: true };
        if (!isProjectEditable) {
            if (shortModelFormGroup && !shortModelFormGroup.disabled) {
                shortModelFormGroup.disable(disableOptions);
            }

            if (extendedModelFormGroup && !extendedModelFormGroup.disabled) {
                extendedModelFormGroup.disable(disableOptions);
            }
        }
    }

    public static async confirmIfShouldProceedWithNavigatingAway(
        projectStatus: ProjectStatus,
        isExternalDocumentManagementEnabled: boolean,
        documentSvc: DocumentsService,
        projectSvc: ProjectsService,
        modalSvc: NzModalService,
        translate: TranslateService
    ): Promise<boolean> {
        const projectLeaveAction = ProjectUtils.doesOpenedProjectNeedActionBeforeLeaving(
            projectStatus,
            isExternalDocumentManagementEnabled,
            projectSvc,
            documentSvc
        );

        let isConfirmed = true;
        let uploadsInProgressSubscription: Subscription;
        if (
            projectLeaveAction.dueToProjectStatus ||
            projectLeaveAction.dueToUploadInProgress ||
            projectLeaveAction.dueToNewDocuments
        ) {
            const modalInstance = ModalUtils.createOpenedProjectNeedsActionConfirmation(
                modalSvc,
                translate,
                this.calculateProjectLeaveMessage(projectLeaveAction, translate)
            );
            if (projectLeaveAction.dueToUploadInProgress) {
                // eslint-disable-next-line no-async-promise-executor
                isConfirmed = await new Promise<boolean>(async (resolve) => {
                    uploadsInProgressSubscription =
                        documentSvc.hypoDossierDocumentUploadsInProgressObservable.subscribe((uploadsInProgress) => {
                            if (uploadsInProgress?.length === 0) {
                                tryToUnsubscribeFromSubscription(uploadsInProgressSubscription);
                                projectLeaveAction.dueToUploadInProgress = false;
                                if (projectLeaveAction.dueToProjectStatus || projectLeaveAction.dueToNewDocuments) {
                                    const modalOptions = modalInstance.modalRef.getConfig();
                                    modalInstance.modalRef.updateConfig({
                                        ...modalOptions,
                                        nzContent: this.calculateProjectLeaveMessage(projectLeaveAction, translate)
                                    });
                                } else {
                                    if (modalInstance?.modalRef) {
                                        modalInstance.modalRef.close();
                                    }
                                    resolve(true);
                                }
                            }
                        });

                    const isLeaveConfirmed = await modalInstance.isConfirmedPromise;
                    tryToUnsubscribeFromSubscription(uploadsInProgressSubscription);
                    if (isLeaveConfirmed && projectLeaveAction.dueToUploadInProgress) {
                        documentSvc.cancelDocumentUploads(documentSvc.getHypoDossierDocumentUploadsInProgress());
                    }
                    resolve(isLeaveConfirmed);
                });
            } else {
                isConfirmed = await modalInstance.isConfirmedPromise;
            }
        }

        return isConfirmed;
    }

    public static doesOpenedProjectNeedActionBeforeLeaving(
        projectStatus: ProjectStatus,
        isExternalDocumentManagementEnabled: boolean,
        projectsSvc: ProjectsService,
        documentSvc: DocumentsService
    ): IProjectLeaveAction {
        const dueToProjectStatus = projectsSvc.doesOpenedProjectNeedActionBeforeLeavingBasedOnStatus(projectStatus);

        const dueToUploadInProgress = !!documentSvc.getHypoDossierDocumentUploadsInProgress()?.length;

        let dueToNewDocuments = false;
        if (isExternalDocumentManagementEnabled) {
            dueToNewDocuments = DocumentUtils.doDocumentsNeedActionBeforeLeaving(documentSvc);
        }

        return {
            dueToProjectStatus,
            dueToUploadInProgress,
            dueToNewDocuments
        };
    }

    public static calculateProjectLeaveMessage(
        projectLeaveAction: IProjectLeaveAction,
        translate: TranslateService
    ): string {
        const messages = [];

        if (projectLeaveAction.dueToProjectStatus) {
            messages.push(translate.instant('project.confirm_leave_with_pending_actions_modal.description'));
        }

        if (projectLeaveAction.dueToUploadInProgress) {
            messages.push(translate.instant('project.confirm_leave_with_document_uploads_in_progress.description'));
        }

        if (projectLeaveAction.dueToNewDocuments) {
            messages.push(translate.instant('project.confirm_leave_with_not_submitted_documents_modal.description'));
        }

        return messages.join('</br></br>');
    }

    public static isProjectStatusAtMost(
        latestStatus: ProjectStatus,
        currentStatus: ProjectStatus,
        extraStatuses: Array<ProjectStatus>,
        disallowedStatuses: Array<ProjectStatus>
    ): boolean {
        const projectKeys = Object.keys(ProjectStatus);
        const currentStatusIndex = projectKeys.indexOf(currentStatus);
        const latestStatusIndex = projectKeys.indexOf(latestStatus);

        for (const status of disallowedStatuses) {
            if (status === currentStatus) {
                return false;
            }
        }

        if (currentStatusIndex <= latestStatusIndex) {
            return true;
        }

        for (const status of extraStatuses) {
            const statusIndex = projectKeys.indexOf(status);

            if (currentStatusIndex <= statusIndex) {
                return true;
            }
        }

        return false;
    }

    public static isProjectStatusAfter(
        currentStatus: ProjectStatus,
        determiningStatus: ProjectStatus,
        doIncludeSelf = true
    ): boolean {
        return EnumUtils.isElementAfter(ProjectStatus, currentStatus, determiningStatus, doIncludeSelf);
    }

    public static hasProjectJustBeenSubmittedToCC(
        previousProjectStatus: ProjectStatus,
        currentProjectStatus: ProjectStatus
    ): boolean {
        return this.hasProjectJustEnteredStatus(
            previousProjectStatus,
            currentProjectStatus,
            ProjectStatus.CompetenceCenterCheck
        );
    }

    public static hasProjectJustBeenSubmittedToLenders(
        previousProjectStatus: ProjectStatus,
        currentProjectStatus: ProjectStatus
    ): boolean {
        return this.hasProjectJustEnteredStatus(
            previousProjectStatus,
            currentProjectStatus,
            ProjectStatus.SubmittedToLenders
        );
    }

    public static navigateToProject = async (
        projectId: number,
        routerSvc: RouterService,
        extras?: NavigationExtras,
        isAbsoluteRoute?: boolean
    ): Promise<void> => {
        await routerSvc.navigateToRoute(PROJECT_PAGE_URL_SUBITEMS_TEMPLATE, [projectId], extras, isAbsoluteRoute);
    };

    public static canCopyExistingProject(businessType: BusinessType): boolean {
        return (
            businessType === BusinessType.Extension ||
            businessType === BusinessType.Increase ||
            businessType === BusinessType.Redemption ||
            businessType === BusinessType.Consolidation
        );
    }

    public static isFinancingPartnerOptional(businessType: BusinessType): boolean {
        return businessType === BusinessType.Redemption || businessType === BusinessType.Consolidation;
    }

    public static isFinancingPartnerUnsupportedForBusinessType(
        businessType: BusinessType,
        financingPartnerId: number,
        unsupportedPartnersPerBusinessType: Array<IUnsupportedPartnersPerBusinessType>
    ): boolean {
        if (!unsupportedPartnersPerBusinessType?.length) {
            return false;
        }

        return !!unsupportedPartnersPerBusinessType.find(
            (u) => u.businessType === businessType && u.partnerIds.includes(financingPartnerId)
        );
    }

    public static getInstitutionsData(institutions: Array<IInstitution>): IInstitutionsData {
        if (!institutions?.length) {
            return null;
        }

        const dropdownOptions: Array<ISelectOption> = [];
        const namesByValue: Record<string, string> = {};

        institutions.forEach((institution) => {
            dropdownOptions.push({
                label: institution.name,
                value: institution.value,
                isDisabled: institution.isDisabled
            });
            namesByValue[institution.value] = institution.name;
        });

        return { institutions, dropdownOptions, namesByValue };
    }

    public static hasAnyMutationExceptIgnored(
        oldProject: IProject,
        newProject: IProject,
        ignoredMutationPaths: Array<string | RegExp> = IGNORED_PROJECT_MUTATION_PATHS
    ): boolean {
        const mutations = calculateMutations(oldProject, newProject);

        const filteredMutations = ignoredMutationPaths?.length
            ? mutations.filter(
                  (mutation) =>
                      !ignoredMutationPaths.some((ignoredMutationPath) => {
                          if (ignoredMutationPath instanceof RegExp) {
                              return ignoredMutationPath.test(mutation.path);
                          }

                          return ignoredMutationPath === mutation.path;
                      })
              )
            : mutations;

        return !!filteredMutations?.length;
    }

    public static async requestPageReload(modalSvc: NzModalService, translate: TranslateService): Promise<void> {
        const { isConfirmedPromise } = SfxModalUtils.createErrorModal(
            modalSvc,
            translate.instant('project.error_occurred.title'),
            translate.instant('project.error_occurred.message')
        );
        await isConfirmedPromise;
        window.location.reload();
    }

    public static canAccessDocumentsPageForProject(project: IProject): boolean {
        return !!project?.externalDocumentManagement;
    }

    public static canAccessDocumentsModalForProject(project: IProject): boolean {
        return !project?.externalDocumentManagement;
    }

    public static getDocumentsModalUrlTreeForProject(
        project: IProject,
        router: Router,
        routerSvc: RouterService
    ): UrlTree {
        const { outletName, pathName } = routerSvc.translateOutlet(OUTLET_NAMES.DOCUMENT_MODAL_OUTLET);
        const defaultEditProjectPath = RouterUtils.getDefaultEditProjectRelativePath(project);
        return router.createUrlTree([
            routerSvc.translateRoute(PROJECT_PAGE_URL_SUBITEMS_TEMPLATE, [project.id], true),
            { outlets: { primary: defaultEditProjectPath, [outletName]: [pathName] } }
        ]);
    }

    public static async isProjectCloneDisabled(
        project: IProject,
        projectSvc: ProjectsService,
        authorizationSvc: AuthorizationService
    ): Promise<boolean> {
        if (!project) {
            return true;
        }

        const { businessType, lenderInfo, isProjectGroupFixed, owner } = project;
        let isFinancingPartnerUnsupportedForBusinessType = false;
        if (lenderInfo?.lenderId) {
            try {
                const unsupportedPartnersPerBusinessType = await projectSvc.getUnsupportedPartnersPerBusinessType();
                isFinancingPartnerUnsupportedForBusinessType =
                    ProjectUtils.isFinancingPartnerUnsupportedForBusinessType(
                        businessType,
                        lenderInfo.lenderId,
                        unsupportedPartnersPerBusinessType
                    );
            } catch (ex) {
                console.error(ex);
            }
        }

        return (
            isProjectGroupFixed ||
            isFinancingPartnerUnsupportedForBusinessType ||
            owner?.isDeleted ||
            !authorizationSvc.hasPermission(ProjectPermission.PP_CreateVersionsWithViewRights)
        );
    }

    private static hasProjectJustEnteredStatus(
        previousProjectStatus: ProjectStatus,
        currentProjectStatus: ProjectStatus,
        relevantStatus: ProjectStatus
    ): boolean {
        if (!previousProjectStatus || !currentProjectStatus || !relevantStatus) {
            return false;
        }
        return (
            !ProjectUtils.isProjectStatusAfter(previousProjectStatus, relevantStatus) &&
            ProjectUtils.isProjectStatusAfter(currentProjectStatus, relevantStatus)
        );
    }
}
