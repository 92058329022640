import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { IdentityProvider, LOGIN_QUERY } from '../constants';
import { RouterService } from '../core/services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../core/services/session-storage.service';
import { AuthenticationService } from '../projects/services/authentication.service';
import { LoaderComponent } from 'sfx-commons';

@Component({
    templateUrl: './sfx-admin-fallback-login.component.html',
    imports: [LoaderComponent]
})
export class SfxAdminFallbackLoginComponent implements OnInit {
    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly authSvc: AuthenticationService,
        private readonly routerSvc: RouterService,
        private readonly sessionStorageSvc: SessionStorageService
    ) {}

    public async ngOnInit(): Promise<void> {
        await this.loginWithDfsIdentity();
    }

    private loginWithDfsIdentity = async () => {
        const returnUrl = this.activatedRoute.snapshot.queryParams?.[LOGIN_QUERY.RETURN_URL];
        if (this.routerSvc.isValidReturnUrl(returnUrl)) {
            this.sessionStorageSvc.set(
                SESSION_STORAGE_KEY.RETURN_URL_KEY,
                this.activatedRoute.snapshot.queryParams.returnUrl
            );
        } else {
            this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.RETURN_URL_KEY);
        }

        const host = window.location.host;
        const brokerHost = environment.originsPerUserType.Broker;
        if (host !== brokerHost) {
            let location = window.location.toString();
            location = location.replace(host, brokerHost);
            window.location.href = location;
        } else {
            await this.authSvc.login(IdentityProvider.DfsIdentityServer);
        }
    };
}
