import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { PendingSaveRequestsService } from 'sfx-commons';

interface ShouldRequestBeCounted {
    shouldRequestBeCounted: boolean;
    isSilent?: boolean;
}

@Injectable()
export class PendingSaveRequestCountInterceptor implements HttpInterceptor {
    private readExceptions: Array<string> = [
        '/CalculateQuickValuation',
        '/Financings/Extended',
        '/Flat',
        '/SingleFamilyHouse',
        '/IsOfferBindingCancellationPossible',
        '/Documents/GetHDDocumentDownloadRequestStatus',
        '/Documents/ObtainHDGetDocumentDownloadRequestId'
    ];
    private readonly writeExceptions: Array<string> = ['/Offer/', '/Offers'];

    // Silent counted requests will not block user interaction (closing modals, etc.), but just display a save indicator where needed.
    private readonly silentCountedRequests: Array<string> = [
        '/AsyncOffers',
        '/Documents/OfferOverviewDocumentGeneration',
        '/Documents/Upload',
        '/Documents/DossierStatus'
    ];
    private pendingRequestsCount = 0;
    private pendingSilentRequestsCount = 0;

    constructor(private readonly pendingSaveRequestsSvc: PendingSaveRequestsService) {}

    public intercept(request: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const { shouldRequestBeCounted, isSilent } = this.shouldRequestBeCounted(request);

        if (shouldRequestBeCounted) {
            if (isSilent) {
                this.pendingSilentRequestsCount++;
            }

            this.modifyCount(1);
        }
        return next.handle(request).pipe(
            finalize(() => {
                if (shouldRequestBeCounted) {
                    if (isSilent) {
                        this.pendingSilentRequestsCount--;
                    }

                    this.modifyCount(-1);
                }
            })
        );
    }

    private modifyCount(modifier: number): void {
        this.pendingRequestsCount += modifier;
        const current = this.pendingSaveRequestsSvc.isSaveInProgressSubject.getValue();
        const isSaveInProgress = this.pendingRequestsCount > 0;
        const hasOnlySilentRequestsInProgress =
            isSaveInProgress && this.pendingRequestsCount === this.pendingSilentRequestsCount;

        if (current.isSaveInProgress !== isSaveInProgress || current.isSilent !== hasOnlySilentRequestsInProgress) {
            this.pendingSaveRequestsSvc.isSaveInProgressSubject.next({
                isSaveInProgress,
                isSilent: hasOnlySilentRequestsInProgress
            });
        }
    }

    private shouldRequestBeCounted(request: HttpRequest<never>): ShouldRequestBeCounted {
        if (this.isExceptionalRequest(this.silentCountedRequests, request)) {
            return { shouldRequestBeCounted: true, isSilent: true };
        }

        if (request.method !== 'GET') {
            return this.isExceptionalRequest(this.writeExceptions, request)
                ? { shouldRequestBeCounted: false }
                : { shouldRequestBeCounted: true };
        } else {
            return this.isExceptionalRequest(this.readExceptions, request)
                ? { shouldRequestBeCounted: true }
                : { shouldRequestBeCounted: false };
        }
    }

    private isExceptionalRequest(array: Array<string>, request: HttpRequest<never>): boolean {
        return array.findIndex((substring) => request.url.includes(substring)) > -1;
    }
}
