import { ISelectOption, isNullOrUndefined } from 'sfx-commons';
import { BorrowerType } from '../../projects/models/enums/borrowerType';
import { IBorrowerNationalities } from '../../projects/models/IBorrowerNationalities';
import { IBorrowerNationality } from '../../projects/models/IBorrowerNationality';
import { IBorrowerOverview } from '../../projects/models/IBorrowerOverview';
import { IBorrowerPersonalInfo } from '../../projects/models/IBorrowerPersonalInfo';
import { IExtendedBorrower } from '../../projects/models/IExtendedBorrower';
import { IListableBorrower } from '../../projects/models/IListableBorrower';

export class BorrowerUtils {
    public static calculateOverallIncomeBasedOnBorrowerType(borrowersData: IBorrowerOverview): number {
        if (!borrowersData) {
            return null;
        }
        if (borrowersData.borrowersType === BorrowerType.Individual) {
            return this.calculateOverallIncome(borrowersData);
        } else {
            return borrowersData.legalEntity ? borrowersData.legalEntity.overallProfit : null;
        }
    }

    public static calculateOverallIncome(borrowersData: IBorrowerOverview): number {
        if (borrowersData?.borrowersType === BorrowerType.Individual) {
            const { firstBorrower, secondBorrower, thirdBorrower } = borrowersData;
            if (
                isNullOrUndefined(firstBorrower?.overallIncome) &&
                isNullOrUndefined(secondBorrower?.overallIncome) &&
                isNullOrUndefined(thirdBorrower?.overallIncome)
            ) {
                return null;
            }

            return (
                (firstBorrower?.overallIncome || 0) +
                (secondBorrower?.overallIncome || 0) +
                (thirdBorrower?.overallIncome || 0)
            );
        }

        return null;
    }

    public static getIndividualBorrowerNames(
        borrowers: Array<IBorrowerPersonalInfo | IListableBorrower>
    ): Array<string> {
        if (borrowers?.length) {
            return borrowers
                .filter((borrower) => borrower?.firstName || borrower?.lastName)
                .map((borrower) => `${borrower.firstName || ''} ${borrower.lastName || ''}`.trim());
        }

        return [];
    }

    public static getSelectedNationalityIds(nationalities: IBorrowerNationalities): Array<number> {
        if (!nationalities) {
            return [];
        }

        const nationalityIds: Array<number> = [];

        Object.values(nationalities).forEach((nationality: IBorrowerNationality) => {
            if (!isNullOrUndefined(nationality?.nationalityId)) {
                nationalityIds.push(nationality.nationalityId);
            }
        });

        return nationalityIds;
    }

    public static setupNationalityOptionsFromBorrowerNationalities(
        extendedBorrower: IExtendedBorrower
    ): Array<ISelectOption<number>> {
        if (!extendedBorrower) {
            return [];
        }

        const nationalityOptions: Array<ISelectOption<number>> = [];

        Object.values(extendedBorrower.personalInfo.nationalities).forEach((nationality: IBorrowerNationality) => {
            if (!isNullOrUndefined(nationality.nationalityId) && nationality.nationalityName) {
                nationalityOptions.push({
                    value: nationality.nationalityId,
                    label: nationality.nationalityName,
                    isDisabled: true
                });
            }
        });

        return nationalityOptions;
    }

    public static calculateNationalitiesSelectionLimit(nationalities: IBorrowerNationalities): number {
        if (!nationalities) {
            return null;
        }

        return Object.keys(nationalities).length;
    }
}
