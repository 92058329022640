/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNullOrUndefined } from 'sfx-commons';

export {};

declare global {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Array<T> {
        updateItem(item: any, key?: string): boolean;
        upsertItem(item: any, key?: string): boolean;
        removeItem(item: any, key?: string): boolean;
        removeAt(index: number): boolean;
    }
}

Array.prototype.updateItem = function (item: any, key?: string): boolean {
    if (!this || !(this instanceof Array)) {
        return false;
    }

    let index = -1;

    if (key) {
        index = this.findIndex((value) => value[key] === item[key]);
    } else {
        index = this.findIndex((value) => value === item);
    }

    if (index >= 0) {
        this.splice(index, 1, item);
        return true;
    }

    return false;
};

Array.prototype.upsertItem = function (item: any, key?: string): boolean {
    if (!this || !(this instanceof Array)) {
        return false;
    }

    const isReplaced = this.updateItem(item, key);

    if (!isReplaced) {
        this.push(item);
    }

    return true;
};

Array.prototype.removeItem = function (item: any, key?: string): boolean {
    if (!this || !(this instanceof Array)) {
        return false;
    }

    let index = -1;

    if (key) {
        index = this.findIndex((value) => value[key] === item[key]);
    } else {
        index = this.findIndex((value) => value === item);
    }

    if (index >= 0) {
        this.splice(index, 1);
        return true;
    }

    return false;
};

Array.prototype.removeAt = function (index: number): boolean {
    if (!this || !(this instanceof Array) || isNullOrUndefined(index) || index < 0 || index >= this.length) {
        return false;
    }

    this.splice(index, 1);
    return true;
};
