import { Injectable } from '@angular/core';
import { ProjectsService } from '../../projects/services/projects.service';

@Injectable({
    providedIn: 'root'
})
export class LogoutImplicationsService {
    constructor(private readonly projectsSvc: ProjectsService) {}

    public clearCache(): void {
        this.projectsSvc.clearCache();
    }
}
