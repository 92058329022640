import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import './app/core/utils/string.utils';
import './app/core/utils/array.utils';
import './app/core/utils/promise.utils';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeItExtra from '@angular/common/locales/extra/it';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { appConfig } from './app/app.config';

if (environment.production) {
    enableProdMode();
}

registerLocaleData(localeEn, 'en', localeEnExtra);
registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeIt, 'it', localeItExtra);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
