import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Operation } from 'fast-json-patch';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CANCELLED_REQUEST_ERROR, LANGUAGE_FILE_NAME_SUFFIX, SafeAny, UPLOAD_STATUSES } from '../../constants';
import { ROUTE_ID } from '../../constants/api-routes';
import { HttpMethod } from '../types/http-method';
import { OfferTriggerChangeService } from './offer-trigger-change.service';
import { RoutesService } from './routes.service';

function getUrl(
    method: HttpMethod,
    routeId: ROUTE_ID,
    params?: object,
    payload?: object,
    doGetWithQuery = false
): string {
    const route = RoutesService.getRouteById(routeId, payload, params);
    if (doGetWithQuery) {
        return `${route}${stringifyParams(params)}`;
    }

    return route;
}

function stringifyParams(params: object): string {
    if (!params || !Object.keys(params).length) {
        return '';
    }
    let stringifiedParams = '?';
    Object.keys(params).map((key, i) => {
        if (i > 0) {
            stringifiedParams += '&';
        }
        if (Array.isArray(params[key])) {
            for (let k = 0; k < params[key].length; k++) {
                stringifiedParams += `${key}=${encodeURIComponent(params[key][k])}`;
                if (k !== params[key].length - 1) {
                    stringifiedParams += '&';
                }
            }
        } else {
            stringifiedParams += `${key}=${encodeURIComponent(params[key])}`;
        }
    });

    return stringifiedParams;
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private subscriptions: { [key: string]: Subscription } = {};
    private sfxCustomCancelledRequestErrorKey = 'sfxCustomCancelledRequestError';

    constructor(
        private readonly http: HttpClient,
        private readonly offerTriggerChangeSvc: OfferTriggerChangeService,
        private readonly translate: TranslateService
    ) {}

    public async delete<T>(
        routeId: ROUTE_ID,
        params?: object,
        payload?: object,
        options?: object,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny,
        cancellationContext?: object
    ): Promise<T> {
        const method = 'DELETE';
        const httpCall = this.httpCall(method, routeId, params, payload, undefined, options);

        return this.handleRequest<T>(null, method, routeId, httpCall, cancellationContext, (response) =>
            this.handleResponseImplications(
                method,
                routeId,
                payload,
                response,
                actionAfterCallBeforeOfferGenNotification
            )
        );
    }

    public async get<T>(
        formGroup: FormGroup,
        routeId: ROUTE_ID,
        params?: object,
        options?: object,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny,
        cancellationContext?: object
    ): Promise<T> {
        const method = 'GET';
        const httpCall = this.httpCall(method, routeId, params, undefined, undefined, options);

        return this.handleRequest<T>(formGroup, method, routeId, httpCall, cancellationContext, (response) =>
            this.handleResponseImplications<T>(
                method,
                routeId,
                undefined,
                response,
                actionAfterCallBeforeOfferGenNotification
            )
        );
    }

    public async getWithQuery<T>(
        routeId: ROUTE_ID,
        params?: object,
        payload?: object,
        options?: object,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny,
        cancellationContext?: object
    ): Promise<T> {
        const method = 'GET';
        const httpCall = this.httpCall(method, routeId, params, payload, true, options);

        return this.handleRequest<T>(null, method, routeId, httpCall, cancellationContext, (response) =>
            this.handleResponseImplications(
                method,
                routeId,
                payload,
                response,
                actionAfterCallBeforeOfferGenNotification
            )
        );
    }

    public async post<T>(
        routeId: ROUTE_ID,
        params?: object,
        payload?: object,
        options?: object,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny,
        cancellationContext?: object
    ): Promise<T> {
        const method = 'POST';
        const httpCall = this.httpCall(method, routeId, params, payload, undefined, options);

        return this.handleRequest<T>(null, method, routeId, httpCall, cancellationContext, (response) =>
            this.handleResponseImplications<T>(
                method,
                routeId,
                payload,
                response,
                actionAfterCallBeforeOfferGenNotification
            )
        );
    }

    public async patch<T>(
        routeId: ROUTE_ID,
        params?: object,
        payload?: object,
        options?: object,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny,
        cancellationContext?: object
    ): Promise<T> {
        const method = 'PATCH';
        const httpCall = this.httpCall(method, routeId, params, payload, undefined, options);

        return this.handleRequest<T>(null, method, routeId, httpCall, cancellationContext, (response) =>
            this.handleResponseImplications<T>(
                method,
                routeId,
                payload,
                response,
                actionAfterCallBeforeOfferGenNotification
            )
        );
    }

    public async jsonPatch<T>(
        formGroup: FormGroup,
        routeId: ROUTE_ID,
        routeParams?: object,
        queryParams?: object,
        payload?: Array<Operation>,
        options?: object,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny,
        cancellationContext?: object
    ): Promise<T> {
        const method = 'PATCH';
        const httpCall = this.httpCall(
            method,
            routeId,
            {
                ...routeParams,
                ...queryParams
            },
            payload,
            false,
            options
        );

        return this.handleRequest<T>(formGroup, method, routeId, httpCall, cancellationContext, (response) =>
            this.handleResponseImplications<T>(
                method,
                routeId,
                payload,
                response,
                actionAfterCallBeforeOfferGenNotification
            )
        );
    }

    public async put<T>(
        routeId: ROUTE_ID,
        params?: object,
        payload?: object,
        options?: object,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny,
        cancellationContext?: object
    ): Promise<T> {
        const method = 'PUT';
        const httpCall = this.httpCall(method, routeId, params, payload, undefined, options);

        return this.handleRequest<T>(null, method, routeId, httpCall, cancellationContext, (response) =>
            this.handleResponseImplications<T>(
                method,
                routeId,
                payload,
                response,
                actionAfterCallBeforeOfferGenNotification
            )
        );
    }

    public upload = (
        routeId: ROUTE_ID,
        documentId: string,
        params?: object,
        payload?: object,
        options?: object,
        canBeCancelled?: boolean
    ): Observable<SafeAny> => {
        return this.uploadPrototype('POST', routeId, documentId, params, payload, options, canBeCancelled);
    };

    public updateUpload = (
        routeId: ROUTE_ID,
        documentId: string,
        params?: object,
        payload?: object,
        options?: object,
        canBeCancelled?: boolean
    ): Observable<SafeAny> => {
        return this.uploadPrototype('PUT', routeId, documentId, params, payload, options, canBeCancelled);
    };

    private uploadPrototype = (
        method: HttpMethod,
        routeId: ROUTE_ID,
        documentId: string,
        params?: object,
        payload?: object,
        options?: object,
        canBeCancelled?: boolean
    ): Observable<SafeAny> => {
        const subscriptionKey = this.getRequestSubscriptionKey(routeId, method, { documentId });

        if (canBeCancelled) {
            this.cancelRequestBySubscriptionKey(subscriptionKey);
        }

        return new Observable((observer) => {
            const subscription = this.httpCall(
                method,
                routeId,
                params,
                payload,
                undefined,
                {
                    ...options,
                    observe: 'events',
                    reportProgress: true
                },
                null
            )
                .pipe(
                    map((event) => {
                        switch (event.type) {
                            case HttpEventType.UploadProgress:
                                // eslint-disable-next-line no-case-declarations
                                const progress = Math.round((100 * event.loaded) / event.total);
                                return {
                                    status: UPLOAD_STATUSES.PROGRESS,
                                    progress
                                };
                            case HttpEventType.Response:
                                return {
                                    status: UPLOAD_STATUSES.RESPONSE,
                                    response: event.body
                                };
                            default:
                                return event;
                        }
                    }),
                    finalize(() => {
                        if (!observer.closed) {
                            observer.error(CANCELLED_REQUEST_ERROR);
                        }
                    })
                )
                .subscribe({
                    next: (data) => observer.next(data),
                    error: (error) => observer.error(error),
                    complete: () => {
                        observer.complete();
                        this.deleteSubscriptionForRequest(subscriptionKey);
                    }
                });
            if (canBeCancelled) {
                this.subscriptions[subscriptionKey] = subscription;
            }
        });
    };

    public jsonp(url: string, callback: string): Observable<object> {
        return this.http.jsonp(url, callback);
    }

    public cancelRequest(
        routeId: ROUTE_ID,
        method: HttpMethod,
        cancellationContext?: object,
        customCancelledRequestError?: string
    ): void {
        const subscriptionKey = this.getRequestSubscriptionKey(routeId, method, cancellationContext);
        this.cancelRequestBySubscriptionKey(subscriptionKey, customCancelledRequestError);
    }

    private cancelRequestBySubscriptionKey(subscriptionKey: string, customCancelledRequestError?: string): void {
        if (this.subscriptions[subscriptionKey] && !this.subscriptions[subscriptionKey].closed) {
            if (!!customCancelledRequestError) {
                this.subscriptions[subscriptionKey][this.sfxCustomCancelledRequestErrorKey] =
                    customCancelledRequestError;
            }

            this.subscriptions[subscriptionKey].unsubscribe();
        }
    }

    private httpCall(
        method: HttpMethod,
        routeId: ROUTE_ID,
        params?: object,
        payload?: object,
        doGetWithQuery = false,
        options?: object,
        contentType = 'application/json'
    ): Observable<SafeAny> {
        if (!payload) {
            payload = params;
        }

        try {
            const withCredentials = false;
            const extraHeaders = {};

            const headers = new HttpHeaders({
                'Accept-Language':
                    this.translate.currentLang || LANGUAGE_FILE_NAME_SUFFIX[environment.supportedLanguages[0]],
                ...extraHeaders
            });
            if (contentType !== null) {
                headers.append('Content-Type', contentType);
            }

            const requestOptions = {
                ...options,
                headers,
                body: payload,
                withCredentials
            };

            return this.http.request(method, getUrl(method, routeId, params, payload, doGetWithQuery), requestOptions);
        } catch (error) {
            console.error(error);

            return new Observable((observer) => observer.error(error));
        }
    }

    private deleteSubscriptionForRequest(subscriptionKey): void {
        if (this.subscriptions[subscriptionKey]) {
            delete this.subscriptions[subscriptionKey];
        }
    }

    private getRequestSubscriptionKey(routeId: ROUTE_ID, method: HttpMethod, cancellationContext?: object): string {
        const keyElements: Array<string> = [routeId, method];
        const additionalInfo = cancellationContext
            ? Object.keys(cancellationContext)
                  .map((key) => cancellationContext[key])
                  .join('_')
            : null;
        if (!!additionalInfo) {
            keyElements.push(additionalInfo.split(' ').join('_'));
        }
        return keyElements.join('_');
    }

    private signalOfferGenerationIfNecessary(method: HttpMethod, routeId: ROUTE_ID): void {
        if (this.offerTriggerChangeSvc.didImmediateOfferGeneratingTriggerHappen(method, routeId)) {
            this.offerTriggerChangeSvc.signalOfferGenerationTrigger();
        }
    }

    private handleRequest<T>(
        formGroup: FormGroup,
        method: HttpMethod,
        routeId: ROUTE_ID,
        httpCall: Observable<SafeAny>,
        cancellationContext?: object,
        onSuccess?: (response?: T) => SafeAny
    ): Promise<T> {
        const subscriptionKey = this.getRequestSubscriptionKey(routeId, method, cancellationContext);
        if (cancellationContext) {
            formGroup?.oneRequestDone();
            this.cancelRequestBySubscriptionKey(subscriptionKey);
        }

        return new Promise((resolve, reject) => {
            formGroup?.oneRequestTriggered();
            let isSubscriptionCompleted = false;
            const subscription = httpCall
                .pipe(
                    finalize(() => {
                        if (!isSubscriptionCompleted) {
                            const cancelledRequestError: string =
                                this.subscriptions[subscriptionKey]?.[this.sfxCustomCancelledRequestErrorKey] ||
                                CANCELLED_REQUEST_ERROR;
                            this.deleteSubscriptionForRequest(subscriptionKey);
                            reject(cancelledRequestError);
                        }
                    })
                )
                .subscribe({
                    next: async (response: T) => {
                        isSubscriptionCompleted = true;
                        formGroup?.oneRequestDone();
                        this.deleteSubscriptionForRequest(subscriptionKey);

                        if (onSuccess) {
                            await onSuccess(response);
                        }
                        resolve(response);
                    },
                    error: (error) => {
                        isSubscriptionCompleted = true;
                        formGroup?.oneRequestDone();
                        this.deleteSubscriptionForRequest(subscriptionKey);
                        reject(error);
                    }
                });

            if (cancellationContext) {
                this.subscriptions[subscriptionKey] = subscription;
            }
        });
    }

    private async handleResponseImplications<T>(
        method: HttpMethod,
        routeId: ROUTE_ID,
        payload: object,
        response: T,
        actionAfterCallBeforeOfferGenNotification?: (response: T) => SafeAny
    ): Promise<void> {
        const offerTriggeringChangeCondition = this.didOfferTriggeringChangeOccur(method, routeId, payload);

        if (actionAfterCallBeforeOfferGenNotification) {
            await actionAfterCallBeforeOfferGenNotification(response);
        }

        if (offerTriggeringChangeCondition) {
            this.offerTriggerChangeSvc.didOfferTriggeringChangeOccur();
        }
        this.signalOfferGenerationIfNecessary(method, routeId);
    }

    private didOfferTriggeringChangeOccur(method: HttpMethod, routeId: ROUTE_ID, payload: object) {
        let offerTriggeringChangeCondition = false;
        switch (method) {
            case 'GET':
                offerTriggeringChangeCondition = this.offerTriggerChangeSvc.doesGetRouteTriggerOfferChange(routeId);
                break;
            case 'POST':
                offerTriggeringChangeCondition = this.offerTriggerChangeSvc.doesPostRouteTriggerOfferChange(routeId);
                break;
            case 'DELETE':
                offerTriggeringChangeCondition = this.offerTriggerChangeSvc.doesDeleteRouteTriggerOfferChange(routeId);
                break;
            case 'PATCH':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (payload instanceof Array<Operation>) {
                    offerTriggeringChangeCondition = !!this.offerTriggerChangeSvc.hasPatchOfferTriggeringMutation(
                        routeId,
                        payload as Array<Operation>
                    )?.length;
                }
                break;
            case 'PUT':
            default:
                offerTriggeringChangeCondition = false;
                break;
        }

        return offerTriggeringChangeCondition;
    }
}
