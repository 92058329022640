/* eslint-disable */
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Operation } from 'fast-json-patch/module/core';
import { CalculatedMutationOperationPairsType } from 'sfx-commons';
import { environment } from '../../../environments/environment';
import { CANCELLED_REQUEST_DUE_TO_INCOMPLETE_DATA, CANCELLED_REQUEST_ERROR, SafeAny } from '../../constants';

type PatchOptions = { onlySelf?: boolean; emitEvent?: boolean };

export function nameOfDirectProperty<T>(func: (context: T) => SafeAny, context: T, isGetter = false): string {
    const parsedFunc = func
        .toString()
        .split('=>')
        .map((item) => item.trim());

    if (parsedFunc.length !== 2) {
        throw new Error(`Invalid lambda function: '${func.toString()}'`);
    }

    const returnValue = stripAssertionOperators(parsedFunc[1]).split('.');
    if (returnValue.length !== 2) {
        throw new Error(`Invalid expression for property: '${returnValue}'`);
    }

    const supposedPropertyName = returnValue[1];
    if (func(context) === context[supposedPropertyName] || isGetter) {
        return supposedPropertyName;
    }

    throw new Error(`Could not find matching property with correct value for lambda function '${func}'`);
}

export function pathOfProperty<T>(func: (context: T) => SafeAny): string {
    const parsedFunc = func
        .toString()
        .split('=>')
        .map((item) => item.trim());

    if (parsedFunc.length !== 2) {
        throw new Error(`Invalid lambda function: '${func.toString()}'`);
    }

    const returnValue = stripAssertionOperators(parsedFunc[1]).split('.');
    if (returnValue.length < 2) {
        throw new Error(`Invalid expression for property: '${returnValue}'`);
    }

    return `/${returnValue.slice(1).join('/')}`;
}

function stripAssertionOperators(value: string): string {
    return value?.replace(/[?!]/g, '');
}

export function getMutationObject(operations: Array<Operation>): SafeAny {
    const object = {};

    operations.forEach((operation) => {
        const keys = operation.path.split('/').filter((k) => k !== '');
        let temp = object;
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (!temp[key]) {
                temp[key] = {};
            }
            if (i === keys.length - 1) {
                temp[key] = 'value' in operation ? operation.value : null;
            } else {
                temp = temp[key];
            }
        }
    });

    return !Object.keys(object).length ? object : transformNumericKeyedObjectsToArrays(object);
}

function transformNumericKeyedObjectsToArrays(object: SafeAny): SafeAny {
    if (Array.isArray(object)) {
        return object.map(transformNumericKeyedObjectsToArrays);
    } else if (typeof object === 'object' && object !== null) {
        if (Object.keys(object).every((k) => !isNaN(Number(k)))) {
            const maxIndex = Math.max(...Object.keys(object).map(Number));
            const array = Array.from(
                { length: maxIndex + 1 },
                (_, index) => transformNumericKeyedObjectsToArrays(object[index] || null) // [null, value, null, value] is completely fine object when patching form arrays, meaning that Angular will only patch values on 1st and 3rd indices. Indices with `null` value will be ignored.
            );
            return array;
        }

        return Object.fromEntries(
            Object.entries(object).map(([key, value]) => [key, transformNumericKeyedObjectsToArrays(value)])
        );
    }

    return object;
}

export function unregisterSfxStatusChanges(abstractControl: AbstractControl): void {
    if (abstractControl) {
        abstractControl.unregisterSfxStatusChanges();
    }
}

export function isUserPilotFlowEnabled(userPilotFlow: { isEnabled: boolean }): boolean {
    return !!environment.features?.userPilot?.isEnabled && !!userPilotFlow?.isEnabled;
}

export function wait(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isAccessForbiddenError(error: SafeAny): boolean {
    return !!error && error instanceof HttpErrorResponse && error.status === HttpStatusCode.Forbidden;
}

export function isCancelledRequestError(error: SafeAny): boolean {
    return !!error && (error === CANCELLED_REQUEST_ERROR || error === CANCELLED_REQUEST_DUE_TO_INCOMPLETE_DATA);
}

export function getHttpStatusCodeFromError(error: SafeAny): HttpStatusCode {
    if (error instanceof HttpErrorResponse) {
        return error.status;
    }

    return null;
}

export function patchAffectedControls<
    TFormGroup extends { [K in keyof TFormGroup]: AbstractControl<SafeAny> },
    TFormModel
>(
    formGroup: FormGroup<TFormGroup>,
    formGroupModel: TFormModel,
    formCreationFn?: () => FormGroup<TFormGroup>,
    options?: PatchOptions
): void {
    if (!formGroup || !formGroupModel) {
        return;
    }

    const mutations = formGroup.calculateMutations(formGroupModel, false, false, true);
    const hasAddOrRemoveMutations = !!mutations.find((m) => m.op === 'add' || m.op === 'remove');

    if (hasAddOrRemoveMutations && formCreationFn) {
        formCreationFn();
        return;
    }

    const replaceMutations = mutations.filter((m) => m.op === 'replace');
    if (replaceMutations.length) {
        const mutationObject = getMutationObject(replaceMutations);
        if (mutationObject && Object.keys(mutationObject).length) {
            formGroup.patchValue(mutationObject, options);
        }
    }
}

export function markControlsAsPristine(mutationOperationPairs: CalculatedMutationOperationPairsType): void {
    if (mutationOperationPairs?.length) {
        mutationOperationPairs.forEach(({ control }) => {
            if (control) {
                control.markAsPristine();
            }
        });
    }
}
