import { Injectable } from '@angular/core';
import { OUTLET_NAMES, SUPPORTED_LANGUAGE } from '../../constants';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';
import { ObjectUtils } from '../utils/object.utils';
import { AppInitializerService } from './app-initializer.service';
import { RouterService } from './router.service';

@Injectable({
    providedIn: 'root'
})
export class TranslateRouterService {
    constructor(
        private readonly routerSvc: RouterService,
        private readonly madTranslateSvc: MadTranslateService
    ) {}

    public async parseAndTranslateUrl(language: SUPPORTED_LANGUAGE, pathName: string): Promise<string> {
        const oldLanguage = this.madTranslateSvc.getSelectedLanguage();
        if (language === oldLanguage) {
            return pathName;
        }
        await this.madTranslateSvc.setSelectedLanguage(language, false);

        const splitPathName = pathName.split('/');
        let index = 0;
        if (splitPathName.length >= 1) {
            index = 1;
        }
        splitPathName.splice(index, 1);

        const newUrlSegments = splitPathName.map((path) => {
            if (path.length) {
                const separatorIndex = path.indexOf(';');
                if (separatorIndex > -1) {
                    path = path.substring(0, separatorIndex);
                }

                const outletSeparatorIndex = path.indexOf(':');
                if (outletSeparatorIndex > -1) {
                    let pathWithOutlets: Array<string>;
                    if (path.indexOf('(') > 0) {
                        pathWithOutlets = path.split('(');
                        path = this.parseAndTranslatePath(pathWithOutlets[0], oldLanguage);
                        for (let i = 1; i < pathWithOutlets.length; i++) {
                            path += this.parseAndTranslateOutletPath(`(${pathWithOutlets[i]}`);
                        }
                    } else {
                        path = this.parseAndTranslateOutletPath(path);
                    }
                } else {
                    path = this.parseAndTranslatePath(path, oldLanguage);
                }
            }

            return path;
        });

        newUrlSegments.splice(1, 0, this.madTranslateSvc.getSelectedLanguageFileSuffix());
        return newUrlSegments.join('/');
    }

    private parseAndTranslatePath(path: string, oldLanguage: string): string {
        const pathNoParantheses = this.getPathNoParantheses(path);

        const key = ObjectUtils.getKeyForValue<string>(
            AppInitializerService.asyncRoutes[oldLanguage],
            pathNoParantheses
        );
        if (key) {
            const translatedPath = this.routerSvc.translateRoute([key]);
            path = path.replace(pathNoParantheses, translatedPath);
        }

        return path;
    }

    private parseAndTranslateOutletPath(path: string): string {
        const pathNoParantheses = this.getPathNoParantheses(path);
        const outlet = pathNoParantheses.split(':')[0];
        const outletKey = ObjectUtils.getKeyForValue<string>(OUTLET_NAMES, outlet);
        if (outletKey) {
            const { outletName, pathName } = this.routerSvc.translateOutlet(OUTLET_NAMES[outletKey] as OUTLET_NAMES);

            path = path.replace(pathNoParantheses, `${outletName}:${pathName}`);
        }

        return path;
    }

    private getPathNoParantheses(path: string): string {
        return path.split('(').join('').split(')').join('');
    }
}
