import { Pipe, PipeTransform } from '@angular/core';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { isNullOrUndefined, isNumeric } from 'sfx-commons';
import { SafeAny } from '../../constants';
import { RouterService } from '../../core/services/router.service';

@Pipe({
    name: 'translateRoute'
})
export class TranslateRoutePipe implements PipeTransform {
    constructor(private readonly routerSvc: RouterService) {}

    public transform(routeSubItems: Array<string | number>, ...args: Array<SafeAny>): string {
        let parameters: Array<number> = null;
        let isAbsoluteRoute = true;
        if (args?.length) {
            const config = args[0];
            if (config) {
                const { isAbsolute: isAbsoluteInConfig, ...restOfParams } = config;
                if (!isNullOrUndefined(isAbsoluteInConfig)) {
                    isAbsoluteRoute = isAbsoluteInConfig;
                }
                const keys = Object.keys(restOfParams);
                if (keys.length) {
                    parameters = [];
                    keys.map((key) => {
                        if (!restOfParams[key]) {
                            return;
                        }

                        if (isNumeric(restOfParams[key]) || isNonEmptyString(restOfParams[key])) {
                            parameters.push(restOfParams[key]);
                        } else {
                            Object.values(restOfParams[key])
                                .flat()
                                .filter((value) => !isNullOrUndefined(value))
                                .map((value) => parameters.push(value as SafeAny));
                        }
                    });
                }
            }
        }

        return this.routerSvc.translateRoute(routeSubItems, parameters, isAbsoluteRoute);
    }
}
