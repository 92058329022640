export enum ProjectPermission {
    PP_CreateVersionsWithViewRights = 'PP_CreateVersionsWithViewRights',
    PP_CopyProjectsWithViewRights = 'PP_CopyProjectsWithViewRights',
    PP_CreateDeputyForProject = 'PP_CreateDeputyForProject',
    PP_UploadDeleteDocuments = 'PP_UploadDeleteDocuments',
    PP_OfferBindingAndProjectRelease = 'PP_OfferBindingAndProjectRelease',
    PP_DeleteProject = 'PP_DeleteProject',
    PP_InterestRateFixing = 'PP_InterestRateFixing',
    PP_RevokeProjects = 'PP_RevokeProjects',
    PP_TeamSellingFullAccess = 'PP_TeamSellingFullAccess',
    PP_TeamSellingReadOnlyAccess = 'PP_TeamSellingReadOnlyAccess',
    PP_ViewCommunicationWithFinancingPartner = 'PP_ViewCommunicationWithFinancingPartner',
    PP_CommunicateWithFinancingPartners = 'PP_CommunicateWithFinancingPartners'
}
