import { Route, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthorizedRootComponent } from '../../authorized-root/authorized-root.component';
import { UnmatchedRouteComponent } from '../../common-components/unmatched-route/unmatched-route.component';
import { LANGUAGE_FILE_NAME_SUFFIX, MAIN_ROUTES, SUPPORTED_LANGUAGE } from '../../constants';
import { Key4TrainingCompletedComponent } from '../../key4-training-completed/key4-training-completed.component';
import { LogoutComponent } from '../../logout/logout.component';
import { SfxAdminFallbackLoginComponent } from '../../sfx-admin-fallback-login/sfx-admin-fallback-login.component';
import { authenticatedGuard } from '../guards/authenticated.guard';
import { isAppInitializedGuard } from '../guards/is-app-initialized.guard';
import { unauthenticatedGuard } from '../guards/unauthenticated.guard';
import { AppInitializerService } from '../services/app-initializer.service';

export function generateAppRoutes(): Routes {
    const isEnglishSupported = environment.supportedLanguages.includes(SUPPORTED_LANGUAGE.EN);
    const fallbackLanguage = isEnglishSupported ? SUPPORTED_LANGUAGE.EN : environment.supportedLanguages[0];

    return [
        ...environment.supportedLanguages.map(
            (language: string): Route => ({
                path: LANGUAGE_FILE_NAME_SUFFIX[language],
                pathMatch: 'full',
                redirectTo: `${LANGUAGE_FILE_NAME_SUFFIX[language]}/${AppInitializerService.asyncRoutes[language][MAIN_ROUTES.LOGIN]}`
            })
        ),
        ...environment.supportedLanguages.map(
            (language: string): Route => ({
                path: LANGUAGE_FILE_NAME_SUFFIX[language],
                canActivate: [isAppInitializedGuard],
                children: [
                    {
                        path: MAIN_ROUTES.LOGIN,
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.LOGIN],
                        canActivate: [unauthenticatedGuard],
                        pathMatch: 'full',
                        loadChildren: () => import('../../login/login.routes')
                    },
                    {
                        path: MAIN_ROUTES.LOGIN_BACKOFFICE,
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.LOGIN_BACKOFFICE],
                        canActivate: [unauthenticatedGuard],
                        pathMatch: 'full',
                        component: SfxAdminFallbackLoginComponent
                    },
                    {
                        path: MAIN_ROUTES.LOGOUT,
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.LOGOUT],
                        component: LogoutComponent
                    },
                    {
                        path: MAIN_ROUTES.KEY4_TRAINING_COMPLETED,
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.KEY4_TRAINING_COMPLETED],
                        canActivate: [authenticatedGuard],
                        component: Key4TrainingCompletedComponent
                    },
                    {
                        path: MAIN_ROUTES.LOGGING_IN,
                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.LOGGING_IN],
                        canActivate: [authenticatedGuard],
                        loadChildren: () => import('../../logging-in/logging-in.routes')
                    },
                    {
                        path: '',
                        canActivate: [authenticatedGuard],
                        children: [
                            {
                                path: '',
                                component: AuthorizedRootComponent,
                                children: [
                                    {
                                        path: AppInitializerService.asyncRoutes[language][MAIN_ROUTES.PROJECTS],
                                        title: AppInitializerService.pageTitles[language][MAIN_ROUTES.PROJECTS],
                                        loadChildren: () => import('../../projects/projects.routes')
                                    }
                                ]
                            }
                        ]
                    }
                ]
            })
        ),
        {
            path: '',
            pathMatch: 'full',
            redirectTo: `${LANGUAGE_FILE_NAME_SUFFIX[fallbackLanguage]}/${MAIN_ROUTES.LOGIN}`
        },
        {
            path: MAIN_ROUTES.LOGIN,
            pathMatch: 'full',
            redirectTo: `${LANGUAGE_FILE_NAME_SUFFIX[fallbackLanguage]}/${MAIN_ROUTES.LOGIN}`
        },
        {
            path: '**',
            pathMatch: 'full',
            component: UnmatchedRouteComponent
        }
    ];
}
