import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Params, Router } from '@angular/router';
import { takeLast, takeWhile } from 'rxjs';
import { LOGIN_QUERY, MAIN_ROUTES } from '../../constants';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { RouterService } from '../services/router.service';
import { LocationUtils } from '../utils/location.utils';

@Injectable({ providedIn: 'root' })
class UnauthenticatedGuardService {
    constructor(
        private readonly authSvc: AuthenticationService,
        private readonly router: Router,
        private readonly routerSvc: RouterService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.authSvc.authStatus
                .pipe(
                    takeWhile((authStatus) => !authStatus, true),
                    takeLast(1)
                )
                .subscribe(async (authStatus) => {
                    if (authStatus.isAuthenticated) {
                        const currentNavigation = this.router.getCurrentNavigation();
                        const queryParamsToBe = currentNavigation?.extras.queryParams;
                        if (queryParamsToBe) {
                            const loginErrors = LocationUtils.extractLoginAndLogoutRelatedQueryParams(queryParamsToBe);

                            if (
                                // eslint-disable-next-line no-prototype-builtins
                                loginErrors?.hasOwnProperty(LOGIN_QUERY.RETURN_URL) &&
                                !this.routerSvc.isValidReturnUrl(loginErrors[LOGIN_QUERY.RETURN_URL])
                            ) {
                                delete loginErrors[LOGIN_QUERY.RETURN_URL];
                            }

                            if (Object.keys(loginErrors).length) {
                                resolve(true);
                                return;
                            }
                        }

                        let nextRoute: string;
                        let queryParams: Params = null;
                        try {
                            const returnUrl = route.queryParams?.[LOGIN_QUERY.RETURN_URL];
                            if (returnUrl) {
                                const decodedReturnUrl = decodeURIComponent(returnUrl);

                                nextRoute = this.routerSvc.isValidReturnUrl(decodedReturnUrl) ? decodedReturnUrl : null;
                                const index = nextRoute?.indexOf('?');
                                if (nextRoute && index > -1) {
                                    queryParams = LocationUtils.parseQueryStringFromUri(nextRoute)?.queryParams;
                                    nextRoute = nextRoute.substring(0, index > -1 ? index : nextRoute.length);
                                }
                            }
                        } catch (ex) {
                            console.error(ex);
                        }

                        if (!queryParams) {
                            queryParams = LocationUtils.parseQueryString()?.queryParams || {};
                        }
                        queryParams = queryParams
                            ? LocationUtils.extractNonLoginAndLogoutRelatedQueryParams(queryParams)
                            : null;

                        await this.routerSvc.navigateToRoute(nextRoute || [MAIN_ROUTES.PROJECTS], null, {
                            queryParams
                        });
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
        });
    }
}

export const unauthenticatedGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
    return inject(UnauthenticatedGuardService).canActivate(route);
};
