import { SafeAny } from '../../constants';

declare global {
    interface PromiseConstructor {
        sequentialEach<T>(array: Array<SafeAny>, fn: SafeAny): Promise<T>;
    }
}

Promise.sequentialEach = async function <T>(array, fn): Promise<T> {
    let result;
    for (let i = 0; i < array.length; i++) {
        result = await fn(array[i], i);
    }

    return result;
};
