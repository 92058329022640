import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';
import { DiagnosticLoginInterceptor } from '../services/diagnostic-login.interceptor';
import { ErrorInterceptor } from '../services/error.interceptor';
import { PendingSaveRequestCountInterceptor } from '../services/pending-save-request-count.interceptor';
import { TokenInterceptor } from '../services/token.interceptor';

export function provideSfxDiInterceptors(): Array<Provider> {
    return [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: PendingSaveRequestCountInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: DiagnosticLoginInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ];
}
