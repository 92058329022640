import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    CANCELLED_REQUEST_ERROR,
    DEFAULT_PAGE_THUMBNAIL_WIDTH,
    DefaultEmptyLenderId,
    SafeAny,
    SPECIFIC_DOCUMENT_GROUPS,
    UPLOAD_STATUSES
} from '../../constants';
import { ROUTE_ID } from '../../constants/api-routes';
import { HttpService } from '../../core/services/http.service';
import { DossierFileStatus } from '../models/enums/dossierFileStatus';
import { IAssignDocument } from '../models/IAssignDocument';
import { IBaseDocument } from '../models/IBaseDocument';
import { IDocument, IDossierDocument } from '../models/IDocument';
import { IDocumentDownloadRequestId } from '../models/IDocumentDownloadRequestId';
import { IDocumentDownloadRequestStatus } from '../models/IDocumentDownloadRequestStatus';
import { IDocumentGenerationParams } from '../models/IDocumentGenerationParams';
import { IDocumentGroupSelection } from '../models/IDocumentGroupSelection';
import { IDocumentPageThumbnailBatch } from '../models/IDocumentPageThumbnailBatch';
import { IDocumentToExtract } from '../models/IDocumentToExtract';
import { IDossierStatus } from '../models/IDossierStatus';
import { IGeneratedDocument } from '../models/IGeneratedDocument';
import { IDossierInboxQueueItem, IInboxQueueItem } from '../models/IInboxQueueItem';
import { IRequiredDocuments } from '../models/IRequiredDocuments';
import { IWrappedBoolean } from '../models/IWrappedBoolean';
import { IRequiredDocumentCategory } from '../models/IRequiredDocumentCategory';
import { IRequiredDocumentGroup } from '../models/IRequiredDocumentGroup';
import { IDossierFileStatus } from '../models/IDossierFileStatus';

type DocumentUploadInProgress = {
    documentId: string;
};

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    public currentPreviewedDocumentThumbnailsObservable: Observable<IDocumentPageThumbnailBatch>;
    public previewedDocumentObservable: Observable<IDocument>;
    public documentForVersionsObservable: Observable<IDocument>;
    public isPreviewLoadingObservable: Observable<boolean>;
    public requiredDocumentsObservable: Observable<IRequiredDocuments>;
    public openAssignModalObservable: Observable<void>;
    public openSplitModalObservable: Observable<void>;
    public documentAssignedToGroupObservable: Observable<IAssignDocument>;
    public makeDocumentGroupVisibleObservable: Observable<IAssignDocument>;
    public dossierStatusObservable: Observable<IDossierStatus>;
    public signalGetDossierStatusObservable: Observable<void>;
    public didGetDossierStatusFailObservable: Observable<HttpErrorResponse>;
    public displayOrRemoveDossierStatusNotificationObservable: Observable<boolean>;
    public calculateDocumentsFooterActionObservable: Observable<void>;
    public hypoDossierDocumentUploadsInProgressObservable: Observable<Array<DocumentUploadInProgress>>;
    public pendingRequiredDocumentsRequestsCountObservable: Observable<number>;
    public signalGetRequiredDocumentsObservable: Observable<void>;
    public didGetRequiredDocumentsFailObservable: Observable<HttpErrorResponse>;

    private _inboxQueueObservable: Observable<Array<IInboxQueueItem>>;
    private requiredDocumentsSubject: BehaviorSubject<IRequiredDocuments>;
    private inboxQueueSubject: BehaviorSubject<Array<IInboxQueueItem>>;
    private currentPreviewedDocumentThumbnailsSubject: BehaviorSubject<IDocumentPageThumbnailBatch>;
    private isPreviewLoadingSubject: BehaviorSubject<boolean>;
    private previewedDocumentSubject: BehaviorSubject<IDocument>;
    private documentForVersionsSubject: BehaviorSubject<IDocument>;
    private openAssignModalSubject: Subject<void>;
    private openSplitModalSubject: Subject<void>;
    private documentAssignedToGroupSubject: Subject<IAssignDocument>;
    private makeDocumentGroupVisibleSubject: Subject<IAssignDocument>;
    private dossierStatusSubject: BehaviorSubject<IDossierStatus>;
    private signalGetDossierStatusSubject: Subject<void>;
    private didGetDossierStatusFailSubject: Subject<HttpErrorResponse>;
    private signalDisplayOrRemoveDossierStatusNotificationSubject: Subject<boolean>;
    private isDossierStatusNotificationClosedByUser: BehaviorSubject<boolean>;
    private isDossierStatusPollingInProgress: BehaviorSubject<boolean>;
    private calculateDocumentsFooterActionSubject: Subject<void>;
    private hypoDossierDocumentUploadsInProgressSubject: BehaviorSubject<Array<DocumentUploadInProgress>>;
    private pendingRequiredDocumentsRequestsCountSubject: BehaviorSubject<number>;
    private signalGetRequiredDocumentsSubject: Subject<void>;
    private didGetRequiredDocumentsFailSubject: Subject<HttpErrorResponse>;
    private dossierStatusPollingInterval: ReturnType<typeof setInterval>;
    private getRequiredDocsRequestCount = 0;

    /**
     * Contains the `documentId`s for which the thumbnails have been generated ({@link thumbnailsGeneratedNotificationSubject} has been emitted),
     * but the actual document is still not part of the {@link requiredDocumentsSubject}.
     */
    public missingDocumentIdsWithGeneratedThumbnails: Array<number> = [];

    constructor(
        private readonly httpService: HttpService,
        private readonly nzModalSvc: NzModalService,
        private readonly translateSvc: TranslateService
    ) {
        this.inboxQueueSubject = new BehaviorSubject<Array<IInboxQueueItem>>([]);

        this.currentPreviewedDocumentThumbnailsSubject = new BehaviorSubject<IDocumentPageThumbnailBatch>(undefined);
        this.currentPreviewedDocumentThumbnailsObservable =
            this.currentPreviewedDocumentThumbnailsSubject.asObservable();

        this.isPreviewLoadingSubject = new BehaviorSubject<boolean>(false);
        this.isPreviewLoadingObservable = this.isPreviewLoadingSubject.asObservable();

        this.requiredDocumentsSubject = new BehaviorSubject<IRequiredDocuments>(undefined);
        this.requiredDocumentsObservable = this.requiredDocumentsSubject.asObservable();

        this.previewedDocumentSubject = new BehaviorSubject<IDocument>(undefined);
        this.previewedDocumentObservable = this.previewedDocumentSubject.asObservable();

        this.documentForVersionsSubject = new BehaviorSubject<IDocument>(undefined);
        this.documentForVersionsObservable = this.documentForVersionsSubject.asObservable();

        this.openAssignModalSubject = new Subject<void>();
        this.openAssignModalObservable = this.openAssignModalSubject.asObservable();

        this.openSplitModalSubject = new Subject<void>();
        this.openSplitModalObservable = this.openSplitModalSubject.asObservable();

        this.documentAssignedToGroupSubject = new Subject<IAssignDocument>();
        this.documentAssignedToGroupObservable = this.documentAssignedToGroupSubject.asObservable();

        this.makeDocumentGroupVisibleSubject = new BehaviorSubject<IAssignDocument>(undefined);
        this.makeDocumentGroupVisibleObservable = this.makeDocumentGroupVisibleSubject.asObservable();

        this.dossierStatusSubject = new BehaviorSubject<IDossierStatus>(undefined);
        this.dossierStatusObservable = this.dossierStatusSubject.asObservable();

        this.signalGetDossierStatusSubject = new Subject<void>();
        this.signalGetDossierStatusObservable = this.signalGetDossierStatusSubject.asObservable();

        this.didGetDossierStatusFailSubject = new Subject<HttpErrorResponse>();
        this.didGetDossierStatusFailObservable = this.didGetDossierStatusFailSubject.asObservable();

        this.signalDisplayOrRemoveDossierStatusNotificationSubject = new Subject<boolean>();
        this.displayOrRemoveDossierStatusNotificationObservable =
            this.signalDisplayOrRemoveDossierStatusNotificationSubject.asObservable();

        this.isDossierStatusNotificationClosedByUser = new BehaviorSubject<boolean>(false);

        this.isDossierStatusPollingInProgress = new BehaviorSubject<boolean>(false);

        this.calculateDocumentsFooterActionSubject = new Subject<void>();
        this.calculateDocumentsFooterActionObservable = this.calculateDocumentsFooterActionSubject.asObservable();

        this.hypoDossierDocumentUploadsInProgressSubject = new BehaviorSubject<Array<DocumentUploadInProgress>>(
            undefined
        );
        this.hypoDossierDocumentUploadsInProgressObservable =
            this.hypoDossierDocumentUploadsInProgressSubject.asObservable();

        this.pendingRequiredDocumentsRequestsCountSubject = new BehaviorSubject<number>(
            this.getRequiredDocsRequestCount
        );
        this.pendingRequiredDocumentsRequestsCountObservable =
            this.pendingRequiredDocumentsRequestsCountSubject.asObservable();

        this.signalGetRequiredDocumentsSubject = new Subject<void>();
        this.signalGetRequiredDocumentsObservable = this.signalGetRequiredDocumentsSubject.asObservable();

        this.didGetRequiredDocumentsFailSubject = new Subject<HttpErrorResponse>();
        this.didGetRequiredDocumentsFailObservable = this.didGetRequiredDocumentsFailSubject.asObservable();
    }

    public get inboxQueueObservable(): Observable<Array<IInboxQueueItem>> {
        if (!this._inboxQueueObservable) {
            this._inboxQueueObservable = this.inboxQueueSubject.asObservable();
        }

        return this._inboxQueueObservable;
    }

    public clearCache(): void {
        this.inboxQueueSubject.next([]);
        this.cancelRequestsAndRemoveDossierStatusNotification();
        this.setDossierStatus(undefined);
        this.hypoDossierDocumentUploadsInProgressSubject.next(undefined);
        this.clearRequiredDocumentsCache();
        this.clearPreviewedDocumentCache();
        this.clearVisibleDocumentGroupCache();
    }

    public cancelRequestsAndRemoveDossierStatusNotification(): void {
        this.stopDossierStatusPolling();
        this.cancelGetDossierStatusRequest();
        this.cancelDocumentUploads(this.getHypoDossierDocumentUploadsInProgress());
        this.signalRemoveDossierStatusNotification();
    }

    public clearPreviewedDocumentCache(): void {
        this.currentPreviewedDocumentThumbnailsSubject.next(undefined);
        this.isPreviewLoadingSubject.next(false);
        this.setPreviewedDocument(undefined);
    }

    public clearRequiredDocumentsCache(): void {
        this.requiredDocumentsSubject.next(undefined);
    }

    public clearVisibleDocumentGroupCache(): void {
        this.makeDocumentGroupVisibleSubject.next(undefined);
    }

    public triggerDocumentsFooterActionCalculation(): void {
        this.calculateDocumentsFooterActionSubject.next();
    }

    public async downloadDocumentBlob(projectId: number, id: number): Promise<Blob> {
        return await this.httpService
            .get<Blob>(null, ROUTE_ID.DOCUMENT_BY_ID, { projectId, id }, { responseType: 'blob' })
            .catch((errorResponse) => this.handleBlobErrorResponse(errorResponse));
    }

    public async downloadOfferDocumentBlob(projectId: number, documentId: number): Promise<Blob> {
        return await this.httpService
            .get<Blob>(null, ROUTE_ID.OFFER_DOCUMENT_BY_ID, { projectId, documentId }, { responseType: 'blob' })
            .catch((errorResponse) => this.handleBlobErrorResponse(errorResponse));
    }

    public async downloadDocumentsByOfferId(projectId: number, id: number): Promise<IBaseDocument> {
        return await this.httpService.get<IBaseDocument>(null, ROUTE_ID.DOCUMENTS_BY_OFFER_ID, { projectId, id });
    }

    public async getDocumentDownloadRequestId(projectId: number): Promise<string> {
        const { requestId } = await this.httpService.post<IDocumentDownloadRequestId>(
            ROUTE_ID.DOCUMENT_DOWNLOAD_REQUEST_ID,
            {},
            { projectId },
            undefined,
            undefined,
            {}
        );
        return requestId;
    }

    public cancelGetDocumentDownloadRequestId() {
        this.httpService.cancelRequest(ROUTE_ID.DOCUMENT_DOWNLOAD_REQUEST_ID, 'POST');
    }

    public async getDocumentDownloadRequestStatus(
        projectId: number,
        requestId: string
    ): Promise<IDocumentDownloadRequestStatus> {
        return await this.httpService.get<IDocumentDownloadRequestStatus>(
            null,
            ROUTE_ID.DOCUMENT_DOWNLOAD_REQUEST_STATUS,
            {
                projectId,
                requestId
            },
            undefined,
            undefined,
            {}
        );
    }

    public cancelGetDocumentDownloadRequestStatus() {
        this.httpService.cancelRequest(ROUTE_ID.DOCUMENT_DOWNLOAD_REQUEST_STATUS, 'GET');
    }

    public async deleteDocument(projectId: number, id: number): Promise<void> {
        await this.httpService.delete<void>(ROUTE_ID.DOCUMENT_BY_ID, { projectId, id });
        this.removeDocumentFromPreviewedDocumentCache(projectId, id);
        this.removeDocumentFromRequiredDocumentCache(projectId, id);
    }

    public getDocumentForVersions(): IDocument {
        return this.documentForVersionsSubject.getValue();
    }

    public getHypoDossierDocumentUploadsInProgress(): Array<DocumentUploadInProgress> {
        return this.hypoDossierDocumentUploadsInProgressSubject.getValue();
    }

    public getIsDossierStatusNotificationClosedByUser(): boolean {
        return this.isDossierStatusNotificationClosedByUser.getValue();
    }

    public getIsDossierStatusPollingInProgress(): boolean {
        return this.isDossierStatusPollingInProgress.getValue();
    }

    public async getDocumentPageThumbnails(
        projectId: number,
        documentId: number,
        queryParams?: { from?: number; take?: number; width?: number }
    ): Promise<IDocumentPageThumbnailBatch> {
        if (!queryParams) {
            queryParams = {};
        }

        if (!queryParams.width) {
            queryParams.width = DEFAULT_PAGE_THUMBNAIL_WIDTH;
        }

        this.isPreviewLoadingSubject.next(true);

        const response = await this.httpService.getWithQuery<IDocumentPageThumbnailBatch>(
            ROUTE_ID.DOCUMENT_PAGE_THUMBNAILS,
            {
                projectId,
                id: documentId,
                ...queryParams
            }
        );

        this.currentPreviewedDocumentThumbnailsSubject.next(response);
        this.isPreviewLoadingSubject.next(false);

        return response;
    }

    public async getRequiredDocuments(projectId: number): Promise<IRequiredDocuments> {
        try {
            this.adjustPendingRequiredDocumentsRequestsCountBy(1);
            const response = await this.httpService.get<IRequiredDocuments>(
                null,
                ROUTE_ID.REQUIRED_DOCUMENTS,
                { projectId },
                null,
                null,
                {}
            );

            this.requiredDocumentsSubject.next(response);
            return response;
        } finally {
            this.adjustPendingRequiredDocumentsRequestsCountBy(-1);
        }
    }

    public openAssignDocumentModal(): void {
        this.openAssignModalSubject.next();
    }

    public openSplitDocumentModal(): void {
        this.openSplitModalSubject.next();
    }

    public setDocumentForVersions(document: IDocument): void {
        this.documentForVersionsSubject.next(document);
    }

    public notifyDocumentAssignedToGroup(document: IAssignDocument): void {
        this.documentAssignedToGroupSubject.next(document);
    }

    public makeDocumentGroupVisible(document: IAssignDocument): void {
        this.makeDocumentGroupVisibleSubject.next(document);
    }

    public async uploadDocument(fileName: string, formData: FormData): Promise<IDocument> {
        const inboxQueue = this.inboxQueueSubject.getValue();
        const group = formData.get('group');
        const lenderId = +formData.get('lenderId');
        const documentId = formData.get('documentId') as string;
        const isUpdate = documentId && documentId !== 'null' && documentId !== 'undefined' && documentId.length;
        const projectId = +formData.get('projectId');
        const document: IInboxQueueItem = {
            id: isUpdate
                ? documentId.toString()
                : `${fileName}${inboxQueue.filter((item: IInboxQueueItem) => item.name === fileName).length.toString()}`,
            name: fileName,
            progress: 0,
            didFail: false,
            failures: null,
            group: group ? (group as string) : SPECIFIC_DOCUMENT_GROUPS.NotCategorized,
            lenderId
        };
        this.addDocumentToQueue(document);

        const requestMethod = isUpdate ? this.httpService.updateUpload : this.httpService.upload;
        const requestRouteId = isUpdate ? ROUTE_ID.UPDATE_FILE : ROUTE_ID.UPLOAD_FILE;

        return new Promise<IDocument>((resolve, reject) => {
            requestMethod(requestRouteId, document.id, {}, formData).subscribe({
                next: (data) => {
                    switch (data.status) {
                        case UPLOAD_STATUSES.PROGRESS:
                            this.updateInboxQueueProgress({
                                ...document,
                                progress: data.progress
                            } as IInboxQueueItem);
                            break;
                        case UPLOAD_STATUSES.RESPONSE:
                            this.removeDocumentFromInboxQueue(document.id);
                            if (isUpdate) {
                                this.removeDocumentFromRequiredDocumentCache(projectId, +documentId);
                            }
                            this.addDocumentToRequiredDocumentsCache(data.response);
                            return resolve(data.response);
                    }
                },
                error: (error: { error: { message: string } }) => {
                    document.didFail = true;
                    try {
                        const json = JSON.parse(error.error.message) as { Message: string };
                        document.failures = json.Message;
                    } catch {
                        document.failures = error.error.message;
                    }
                    this.updateInboxQueueProgress(document);
                    reject(error);
                }
            });
        });
    }

    public async uploadDossierDocument(
        fileName: string,
        formData: FormData,
        canBeCancelled = false
    ): Promise<IDossierDocument> {
        const inboxQueue = this.inboxQueueSubject.getValue();
        const group = formData.get('group');
        const lenderId = +formData.get('lenderId');
        const size = +formData.get('size');
        const document: IDossierInboxQueueItem = {
            id: `${fileName}${inboxQueue.filter((item: IInboxQueueItem) => item.name === fileName).length.toString()}`,
            name: fileName,
            progress: 0,
            didFail: false,
            failures: null,
            group: group ? (group as string) : SPECIFIC_DOCUMENT_GROUPS.NotCategorized,
            lenderId,
            size,
            status: DossierFileStatus.Undefined,
            uploadedDate: new Date(),
            updatedDate: new Date()
        };
        this.addDocumentToQueue(document, true);

        return new Promise<IDossierDocument>((resolve, reject) => {
            this.addHypoDossierDocumentUploadInProgress(document.id);

            this.httpService.upload(ROUTE_ID.UPLOAD_FILE, document.id, {}, formData, null, canBeCancelled).subscribe({
                next: (data) => {
                    switch (data.status) {
                        case UPLOAD_STATUSES.PROGRESS:
                            this.updateInboxQueueProgress({
                                ...document,
                                progress: data.progress
                            } as IDossierInboxQueueItem);
                            break;
                        case UPLOAD_STATUSES.RESPONSE:
                            this.removeDocumentFromInboxQueue(document.id);
                            this.removeHypoDossierDocumentUploadInProgress(document.id);
                            return resolve(data.response);
                    }
                },
                error: (error: { error: { message: string } } | string) => {
                    document.didFail = true;
                    if (error !== CANCELLED_REQUEST_ERROR) {
                        const exception = error as { error: { message: string } };
                        try {
                            const json = JSON.parse(exception.error.message) as { Message: string };
                            document.failures = json.Message;
                        } catch {
                            document.failures = exception.error.message;
                        }
                    }
                    this.updateInboxQueueProgress(document);
                    this.removeHypoDossierDocumentUploadInProgress(document.id);
                    reject(error);
                }
            });
        });
    }

    public cancelDocumentUpload(documentId: string): void {
        this.httpService.cancelRequest(ROUTE_ID.UPLOAD_FILE, 'POST', { documentId });
    }

    public cancelDocumentUploads(documentUploadsInProgress: Array<DocumentUploadInProgress>): void {
        documentUploadsInProgress?.forEach(({ documentId }) => {
            this.cancelDocumentUpload(documentId);
        });
    }

    public getCachedRequiredDocuments(): IRequiredDocuments {
        return this.requiredDocumentsSubject.getValue();
    }

    public async setCompleteStatus(
        projectId: number,
        documentGroupIds: Array<IDocumentGroupSelection>
    ): Promise<Array<IDocumentGroupSelection>> {
        return await this.httpService.patch<Array<IDocumentGroupSelection>>(
            ROUTE_ID.DOCUMENT_GROUP_SET_COMPLETE_STATUS,
            null,
            { projectId, documentGroupIds }
        );
    }

    public async extractDocument(projectId: number, payload: IDocumentToExtract): Promise<IDocument> {
        return await this.httpService.post<IDocument>(ROUTE_ID.EXTRACT_DOCUMENT, { projectId }, payload);
    }

    public setPreviewedDocument = (document: IDocument): void => {
        this.previewedDocumentSubject.next(document);
    };

    public async assignDocumentsToGroups(
        projectId: number,
        payload: Array<IAssignDocument>
    ): Promise<Array<IDocument>> {
        return await this.httpService.put<Array<IDocument>>(ROUTE_ID.ASSIGN_DOCUMENT, { projectId }, payload);
    }

    public async offerOverviewDocumentGeneration(
        projectId: number,
        payload?: IDocumentGenerationParams
    ): Promise<IGeneratedDocument> {
        return await this.httpService.post<IGeneratedDocument>(
            ROUTE_ID.OFFER_OVERVIEW_DOCUMENT_GENERATION,
            { projectId },
            payload,
            null,
            null,
            {}
        );
    }

    public async isOverRequiredDocumentsThreshold(projectId: number): Promise<IWrappedBoolean> {
        return await this.httpService.get<IWrappedBoolean>(
            null,
            ROUTE_ID.IS_OVER_REQUIRED_DOCUMENTS_THRESHOLD,
            { projectId },
            null,
            null,
            {}
        );
    }

    public cancelOfferOverviewDocumentGeneration(): void {
        this.httpService.cancelRequest(ROUTE_ID.OFFER_OVERVIEW_DOCUMENT_GENERATION, 'POST');
    }

    public async getDossierStatus(projectId: number): Promise<IDossierStatus> {
        this.isDossierStatusPollingInProgress.next(true);

        try {
            const dossierStatus = await this.httpService.get<IDossierStatus>(
                null,
                ROUTE_ID.DOSSIER_STATUS,
                { projectId },
                null,
                null,
                {}
            );

            this.setDossierStatus(dossierStatus);

            if (this.isDossierProcessingInProgress(dossierStatus)) {
                this.startDossierStatusPolling(projectId);
            } else {
                this.stopDossierStatusPolling();
            }

            return dossierStatus;
        } catch (ex) {
            this.stopDossierStatusPolling();
            throw ex;
        }
    }

    public startDossierStatusPolling(projectId: number): void {
        if (this.dossierStatusPollingInterval) {
            return;
        }

        this.dossierStatusPollingInterval = setInterval(async () => {
            await this.getDossierStatus(projectId);
        }, 5000);
    }

    public stopDossierStatusPolling(): void {
        this.isDossierStatusPollingInProgress.next(false);

        if (this.dossierStatusPollingInterval) {
            clearInterval(this.dossierStatusPollingInterval);
            this.dossierStatusPollingInterval = undefined;
        }
    }

    public cancelGetDossierStatusRequest(): void {
        this.httpService.cancelRequest(ROUTE_ID.DOSSIER_STATUS, 'GET');
    }

    public setDossierStatus(dossierStatus: IDossierStatus): void {
        this.dossierStatusSubject.next(dossierStatus);
    }

    public getCachedDossierStatus(): IDossierStatus {
        return this.dossierStatusSubject.getValue();
    }

    public removeFailedDocumentFromInboxCache(projectId: number, documentQueueEntry: IInboxQueueItem): void {
        const inboxQueue = [...this.inboxQueueSubject.getValue()];
        const index = inboxQueue.findIndex((item: IInboxQueueItem) => item === documentQueueEntry);
        if (index > -1) {
            inboxQueue.splice(index, 1);
            this.inboxQueueSubject.next(inboxQueue);
        }
    }

    public removeDocumentFromPreviewedDocumentCache(projectId: number, documentId: number): void {
        const document = this.previewedDocumentSubject.getValue();
        if (document && document.documentId === documentId && document.projectId === projectId) {
            this.clearPreviewedDocumentCache();
        }
    }

    public updateDocumentInRequiredDocumentsCache(document: IDocument): void {
        let wasFound = false;
        const documentCategories = this.requiredDocumentsSubject.getValue();

        for (const category of documentCategories.categories) {
            for (const group of category.groups) {
                for (let i = 0; i < group.assignedDocuments.length; i++) {
                    if (group.assignedDocuments[i].documentId === document.documentId) {
                        wasFound = true;
                    }
                    if (wasFound) {
                        group.assignedDocuments = [...group.assignedDocuments];
                        group.assignedDocuments[i] = document;
                        break;
                    }
                }
                if (wasFound) {
                    break;
                }
            }
            if (wasFound) {
                break;
            }
        }

        if (wasFound) {
            this.requiredDocumentsSubject.next(documentCategories);
        }
    }

    public removeDocumentFromRequiredDocumentCache(projectId: number, documentId: number): IDocument {
        let result: IDocument = null;
        const documentCategories = this.requiredDocumentsSubject.getValue();
        documentCategories.categories.map((category: IRequiredDocumentCategory) => {
            category.groups.map((group: IRequiredDocumentGroup) => {
                for (let i = 0; i < group.assignedDocuments.length; i++) {
                    if (group.assignedDocuments[i].documentId === documentId) {
                        result = group.assignedDocuments.splice(i, 1)[0];
                    }
                }
            });
        });
        if (result) {
            this.requiredDocumentsSubject.next(documentCategories);
        }
        return result;
    }

    public addDocumentToRequiredDocumentsCache(document: IDocument, previewDocument = true): void {
        const index = this.missingDocumentIdsWithGeneratedThumbnails.findIndex(
            (id: number) => id === document.documentId
        );
        if (index > -1) {
            document.areThumbnailsGenerated = true;
            this.missingDocumentIdsWithGeneratedThumbnails.removeAt(index);
        }

        const requiredDocuments: IRequiredDocuments = this.requiredDocumentsSubject.getValue();
        if (requiredDocuments) {
            for (const category of requiredDocuments.categories) {
                category.groups.map((group: IRequiredDocumentGroup) => {
                    const indexInOldGroups = group.assignedDocuments.findIndex(
                        (doc: IDocument) => doc.documentId === document.documentId && doc.lenderId === document.lenderId
                    );
                    if (indexInOldGroups > -1) {
                        group.assignedDocuments.splice(indexInOldGroups, 1);
                    }

                    if (group.groupType === document.documentGroup && group.lenderId === document.lenderId) {
                        group.assignedDocuments.push(document);
                        if (document.areThumbnailsGenerated && previewDocument) {
                            this.setPreviewedDocument(document);
                        }
                    }
                });
            }
        }

        this.requiredDocumentsSubject.next(requiredDocuments);
    }

    public updateThumbnailsGeneratedForDocInRequiredDocumentsCache(documentId: number): void {
        const requiredDocuments: IRequiredDocuments = this.requiredDocumentsSubject.getValue();
        if (requiredDocuments) {
            let document: IDocument;
            requiredDocuments.categories.map((category: IRequiredDocumentCategory) =>
                category.groups.map((group: IRequiredDocumentGroup) => {
                    const index = group.assignedDocuments.findIndex((doc: IDocument) => doc.documentId === documentId);
                    if (index > -1) {
                        document = { ...group.assignedDocuments[index], areThumbnailsGenerated: true };
                        group.assignedDocuments[index] = document;
                    }
                })
            );
            if (document) {
                const previewedDocument = this.previewedDocumentSubject.getValue();
                if (previewedDocument && previewedDocument.documentId === documentId) {
                    this.setPreviewedDocument(document);
                }

                this.requiredDocumentsSubject.next(requiredDocuments);
            } else {
                if (!this.missingDocumentIdsWithGeneratedThumbnails.includes(documentId)) {
                    this.missingDocumentIdsWithGeneratedThumbnails.push(documentId);
                }
            }
        }
    }

    public updateInboxQueueProgress(document: IInboxQueueItem): void {
        const inboxQueue = [...this.inboxQueueSubject.getValue()];
        inboxQueue.updateItem(document, 'id');
        this.inboxQueueSubject.next(inboxQueue);
    }

    public signalDisplayDossierStatusNotificationIfDossierProcessingIsInProgress(dossierStatus: IDossierStatus): void {
        if (this.isDossierProcessingInProgress(dossierStatus)) {
            this.signalDisplayDossierStatusNotification();
        }
    }

    public signalDisplayDossierStatusNotification(): void {
        this.signalDisplayOrRemoveDossierStatusNotificationSubject.next(true);
    }

    public signalRemoveDossierStatusNotification(): void {
        this.signalDisplayOrRemoveDossierStatusNotificationSubject.next(false);
    }

    public setIsDossierStatusNotificationClosedByUser(value: boolean): void {
        this.isDossierStatusNotificationClosedByUser.next(value);
    }

    public signalGetRequiredDocuments(): void {
        this.signalGetRequiredDocumentsSubject.next();
    }

    public signalGetRequiredDocumentsFailed(exception: HttpErrorResponse): void {
        this.didGetRequiredDocumentsFailSubject.next(exception);
    }

    public signalGetDossierStatus(): void {
        this.signalGetDossierStatusSubject.next();
    }

    public signalGetDossierStatusFailed(exception: HttpErrorResponse): void {
        this.didGetDossierStatusFailSubject.next(exception);
    }

    public isDossierProcessingInProgress(dossierStatus: IDossierStatus): boolean {
        return !!dossierStatus?.files?.some((file: IDossierFileStatus) => file.status === DossierFileStatus.Processing);
    }

    public getUniqueDocumentGroupId(groupId: number, lenderId: number): string {
        return `${groupId}-${lenderId || DefaultEmptyLenderId}`;
    }

    public removeDocumentFromInboxQueue(id: string): void {
        const inboxQueue = [...this.inboxQueueSubject.getValue()];
        const index = inboxQueue.findIndex((item: IInboxQueueItem) => item.id === id);
        if (index > -1) {
            inboxQueue.removeAt(index);
            this.inboxQueueSubject.next(inboxQueue);
        }
    }

    private addDocumentToQueue(document: IInboxQueueItem, shouldBeFirst = false): void {
        const inboxQueue = [...this.inboxQueueSubject.getValue()];
        inboxQueue.splice(shouldBeFirst ? 0 : inboxQueue.length, 0, document);
        this.inboxQueueSubject.next(inboxQueue);
    }

    private async handleBlobErrorResponse(response: HttpErrorResponse): Promise<SafeAny> {
        const newResponse: SafeAny = { ...response };
        if (response?.error instanceof Blob) {
            const errorObjectStringified = await (response.error as Blob).text();
            if (errorObjectStringified) {
                newResponse.error = JSON.parse(errorObjectStringified);
            }
        }
        return new Promise((resolve, reject) => reject(newResponse));
    }

    private addHypoDossierDocumentUploadInProgress(documentId: string): void {
        this.hypoDossierDocumentUploadsInProgressSubject.next([
            ...(this.getHypoDossierDocumentUploadsInProgress() || []),
            { documentId }
        ]);
    }

    private removeHypoDossierDocumentUploadInProgress(documentId: string): void {
        const documentUploadsInProgressClone: Array<DocumentUploadInProgress> = structuredClone(
            this.getHypoDossierDocumentUploadsInProgress()
        );
        const index = documentUploadsInProgressClone.findIndex(
            (document: DocumentUploadInProgress) => document.documentId === documentId
        );

        if (documentUploadsInProgressClone.removeAt(index)) {
            this.hypoDossierDocumentUploadsInProgressSubject.next(documentUploadsInProgressClone);
        }
    }

    private adjustPendingRequiredDocumentsRequestsCountBy(count: number) {
        this.getRequiredDocsRequestCount += count;
        if (this.getRequiredDocsRequestCount < 0) {
            this.getRequiredDocsRequestCount = 0;
        }

        this.pendingRequiredDocumentsRequestsCountSubject.next(this.getRequiredDocsRequestCount);
    }
}
