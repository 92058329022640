import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { LoaderComponent } from 'sfx-commons';
import { LOGIN_QUERY, LOGOUT_REASON, MAIN_ROUTES } from '../constants';
import { RouterService } from '../core/services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../core/services/session-storage.service';
import { LocationUtils } from '../core/utils/location.utils';
import { SSOExtendedConfig } from '../core/utils/sso-config.utils';
import { AuthenticationService } from '../projects/services/authentication.service';

@Component({
    templateUrl: './logout.component.html',
    imports: [LoaderComponent]
})
export class LogoutComponent implements OnInit {
    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly authSvc: AuthenticationService,
        private readonly routerSvc: RouterService,
        private readonly sessionStorageSvc: SessionStorageService
    ) {}

    public async ngOnInit(): Promise<void> {
        // todo: check whether clearing the entire session storage is feasible
        this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN);
        this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.ACTIVE_OFFER_DETAILS_COLLAPSIBLE_PANEL_IDS);

        let returnUrl: string = null;
        let logoutReason: LOGOUT_REASON = null;

        const loginLogoutQueryParams = LocationUtils.extractLoginAndLogoutRelatedQueryParams(
            this.activatedRoute.snapshot.queryParams
        );

        if (
            loginLogoutQueryParams?.[LOGIN_QUERY.RETURN_URL] &&
            !this.routerSvc.isValidReturnUrl(loginLogoutQueryParams[LOGIN_QUERY.RETURN_URL])
        ) {
            delete loginLogoutQueryParams[LOGIN_QUERY.RETURN_URL];
        }

        Object.keys(loginLogoutQueryParams).forEach((key) => {
            const queryParamValue = loginLogoutQueryParams[key];

            if (key === LOGIN_QUERY.RETURN_URL) {
                returnUrl = queryParamValue;
            } else {
                logoutReason = queryParamValue;
            }
        });

        let shouldNavigateToLogin = true;
        if (this.authSvc.hasValidAccessTokenIncludingClockSkew()) {
            const currentIdentityProvider = this.authSvc.currentIdentityProvider;
            const shouldNotRedirectToSSOLogout =
                !SSOExtendedConfig[currentIdentityProvider].shouldRedirectToLogout ||
                (logoutReason !== LOGOUT_REASON.USER_LOGOUT &&
                    (logoutReason !== LOGOUT_REASON.UNAUTHORIZED || !!returnUrl));

            try {
                await this.authSvc.logout(logoutReason, shouldNotRedirectToSSOLogout);

                if (!shouldNotRedirectToSSOLogout) {
                    shouldNavigateToLogin = false;
                }
            } catch (ex) {
                console.log(ex);
            }
        }

        if (shouldNavigateToLogin) {
            await this.navigateToLogin(loginLogoutQueryParams);
        }
    }

    private async navigateToLogin(queryParams: Params): Promise<void> {
        await this.routerSvc.navigateToRoute([MAIN_ROUTES.LOGIN], null, { queryParams, replaceUrl: true });
    }
}
