import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export class TranslateFallbackHttpLoader implements TranslateLoader {
    private static readonly WAIT_FOR_SERVER = 2000;

    constructor(
        private http: HttpClient,
        public prefix: string,
        public suffix = '.json',
        public fallbackPrefix: string = environment.translationsFallbackUrl
    ) {}

    public getTranslation(lang: string): Observable<object> {
        const salt = new Date().getTime();
        return this.http.get(`${this.prefix}${lang}${this.suffix}?${salt}`).pipe(
            timeout(TranslateFallbackHttpLoader.WAIT_FOR_SERVER),
            catchError(() => this.http.get(`${this.fallbackPrefix}${lang}${this.suffix}?${salt}`))
        );
    }
}

export function HttpTranslateLoaderFactory(httpClient: HttpClient): TranslateLoader {
    return new TranslateFallbackHttpLoader(httpClient, environment.translationsUrl);
}
