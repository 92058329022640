import { Type } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalUtils as SfxModalUtils, SafeAny } from 'sfx-commons';

export interface IConfirmModal {
    modalRef: NzModalRef;
    isConfirmedPromise: Promise<boolean>;
}

export class ModalUtils {
    public static createOpenedProjectNeedsActionConfirmation(
        modalSvc: NzModalService,
        translate: TranslateService,
        content: string
    ): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('project.confirm_leave_with_pending_actions_modal.title'),
            translate.instant(content)
        );
    }

    public static createDocumentsNotSubmittedConfirmation(
        modalSvc: NzModalService,
        translate: TranslateService
    ): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('project.confirm_leave_with_not_submitted_documents_modal.title'),
            translate.instant('project.confirm_leave_with_not_submitted_documents_modal.description')
        );
    }

    // public static createCancelDocumentUploadsConfirmation(modalSvc: NzModalService,
    //                                                       translate: TranslateService): IConfirmModal {
    //     return SfxModalUtils.waitForConfirmation(
    //         modalSvc,
    //         translate.instant('project.confirm_leave_with_document_uploads_in_progress.title'),
    //         translate.instant('project.confirm_leave_with_document_uploads_in_progress.description')
    //     );
    // }

    public static createDeleteConfirmation(modalSvc: NzModalService, translate: TranslateService): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('global.delete_warning.title'),
            translate.instant('global.delete_warning.message')
        );
    }

    public static createCloseInvalidModalConfirmation(
        modalSvc: NzModalService,
        translate: TranslateService
    ): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('global.modal_with_pending_changes_warning.title'),
            translate.instant('global.modal_with_pending_changes_warning.message')
        );
    }

    public static createCloneProjectConfirmation(modalSvc: NzModalService, translate: TranslateService): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('project.confirm_clone.title'),
            translate.instant('project.confirm_clone.description')
        );
    }

    public static createOverwriteProjectImportConfirmation(
        modalSvc: NzModalService,
        translate: TranslateService
    ): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('create_project.project_import_overwrite.title'),
            translate.instant('create_project.project_import_overwrite.message'),
            translate.instant('create_project.project_import_overwrite.confirm_button'),
            null,
            null,
            'vertical-center-modal',
            false,
            'icons:exclamation-triangle.solid'
        );
    }

    public static createCancelProjectImportConfirmation(
        modalSvc: NzModalService,
        translate: TranslateService
    ): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('create_project.project_import_cancel.title'),
            translate.instant('create_project.project_import_cancel.message'),
            translate.instant('create_project.project_import_cancel.confirm_button'),
            null,
            null,
            'vertical-center-modal',
            false,
            'icons:exclamation-triangle.solid'
        );
    }

    public static createProceedWithInvalidDocumentsConfirmation(
        modalSvc: NzModalService,
        translate: TranslateService,
        content: Type<SafeAny>,
        componentParams?: Record<string, SafeAny>,
        wrapperClasses?: string
    ): IConfirmModal {
        return SfxModalUtils.waitForConfirmation(
            modalSvc,
            translate.instant('documents.invalid_documents.title'),
            content,
            undefined,
            undefined,
            componentParams,
            wrapperClasses
        );
    }
}
