import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SafeAny } from 'sfx-commons';
import { DiagnosticsService } from '../../diagnostics/services/diagnostics.service';

@Injectable()
export class DiagnosticLoginInterceptor implements HttpInterceptor {
    constructor(private readonly diagnosticsSvc: DiagnosticsService) {}

    public intercept(req: HttpRequest<SafeAny>, next: HttpHandler): Observable<HttpEvent<SafeAny>> {
        if (this.diagnosticsSvc.isActivated && (req.url.includes('/token') || req.url.includes('/Auth/Login'))) {
            const isTokenRequest = req.url.includes('/token');

            return next.handle(req).pipe(
                filter((event: HttpEvent<SafeAny>) => event instanceof HttpResponse),
                switchMap((event: HttpResponse<SafeAny>) => {
                    this.diagnosticsSvc.log({
                        key: isTokenRequest ? 'token' : 'login',
                        value: JSON.stringify(event.body)
                    });
                    return of(event);
                }),
                catchError((err: HttpErrorResponse) => {
                    this.diagnosticsSvc.log({
                        key: isTokenRequest ? 'TOKEN' : 'LOGIN',
                        value: `${err.status}: ${err.message}`
                    });

                    throw err;
                })
            );
        }

        return next.handle(req);
    }
}
