import { HttpErrorResponse, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeAny } from 'sfx-commons';
import {
    CRM_CUSTOMER_ID_QUERY,
    LOGIN_QUERY,
    LOGIN_QUERY_LOGOUT_REASON_MAP,
    LOGOUT_REASON,
    MAIN_ROUTES,
    NOTIFICATION_TYPES
} from '../../constants';
import { ROUTE_ID, ROUTES as API_ROUTES } from '../../constants/api-routes';
import { DiagnosticsService } from '../../diagnostics/services/diagnostics.service';
import { ALREADY_CONFIRMED_LEAVING_PROJECT_NAVIGATION_EXTRAS } from '../../projects/edit-project/constants';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { PopupNotificationService } from './popup-notification.service';
import { RouterService } from './router.service';
import { RoutesService } from './routes.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
    constructor(
        private readonly authSvc: AuthenticationService,
        private readonly diagnosticsSvc: DiagnosticsService,
        private readonly popupNotificationSvc: PopupNotificationService,
        private readonly routerSvc: RouterService,
        private readonly routesService: RoutesService,
        private readonly sessionStorageSvc: SessionStorageService
    ) {}

    public handleError(request: HttpRequest<SafeAny>, error: HttpErrorResponse): boolean {
        if (error.error instanceof ErrorEvent) {
            const value = error.error.message;
            this.diagnosticsSvc.log({ key: 'ErrorHandlerSvc.handleError(ErrorEvent)', value });
            console.error('An error occurred:', value);
            return true;
        } else {
            this.diagnosticsSvc.log({ key: 'ErrorHandlerSvc.handleError(else)', value: JSON.stringify(error) });
            console.error(`HTTP ${error.status}: `, error);
            return this.handleServerError(request, error);
        }
    }

    private handleServerError(request: HttpRequest<SafeAny>, error: HttpErrorResponse): boolean {
        let shouldRethrowError = true;

        switch (error.status) {
            case HttpStatusCode.Unauthorized:
                void this.handleUnauthorizedError(request);
                shouldRethrowError = false;
                break;
            case HttpStatusCode.Forbidden:
                this.handleForbiddenError();
                break;
            // possible further custom status code handling
            default:
                break;
        }

        return shouldRethrowError;
    }

    private async handleUnauthorizedError(request: HttpRequest<SafeAny>): Promise<void> {
        if (request.url.includes(API_ROUTES.LOGOUT)) {
            return;
        }

        if (
            this.routesService.doesUrlMatchRoute(request.url, ROUTE_ID.CURRENT_USER) ||
            this.routesService.doesUrlMatchRoute(request.url, ROUTE_ID.LOGIN)
        ) {
            this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN);
            const authStatus = this.authSvc.getAuthStatus();
            if (authStatus?.isAuthenticated) {
                await this.routerSvc.navigateToLogout({
                    queryParams: {
                        [LOGIN_QUERY_LOGOUT_REASON_MAP[LOGOUT_REASON.UNAUTHORIZED]]: LOGOUT_REASON.UNAUTHORIZED
                    }
                });
            }
            return;
        }

        const logoutRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGOUT], null, true);
        const loginRoute = this.routerSvc.translateRoute([MAIN_ROUTES.LOGIN], null, true);
        const currentUrl = this.routerSvc.currentUrl;
        // Checking the validity of the token will not work in case the token got invalid but the user is still not logged out from the app.
        if (!currentUrl.startsWith(logoutRoute) && !currentUrl.startsWith(loginRoute)) {
            const isCrmCustomerRequest = this.routesService.doesUrlMatchRoute(request.url, ROUTE_ID.CRM_GET_CUSTOMER);
            await this.routerSvc.navigateToLogout({
                queryParams: {
                    [LOGIN_QUERY_LOGOUT_REASON_MAP[LOGOUT_REASON.UNAUTHORIZED]]: LOGOUT_REASON.UNAUTHORIZED,
                    [LOGIN_QUERY.RETURN_URL]: this.routerSvc.getValidReturnUrl(
                        currentUrl,
                        isCrmCustomerRequest ? [CRM_CUSTOMER_ID_QUERY] : undefined
                    )
                },
                ...ALREADY_CONFIRMED_LEAVING_PROJECT_NAVIGATION_EXTRAS
            });
        }
    }

    private handleForbiddenError(): void {
        this.popupNotificationSvc.createNotification(
            NOTIFICATION_TYPES.ERROR,
            'global.access_forbidden.title',
            null,
            'global.access_forbidden.message'
        );
    }
}
