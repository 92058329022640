import { animate, AnimationBuilder, AnimationMetadata, style } from '@angular/animations';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { IconDefinition } from '@fortawesome/angular-fontawesome';
import { faArrowLeft as falArrowLeft, faTimer as falTimer } from '@fortawesome/pro-light-svg-icons';
import {
    faHourglassEnd as farHourglassEnd,
    faHourglassHalf as farHourglassHalf,
    faHourglassStart as farHourglassStart
} from '@fortawesome/pro-regular-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationDataOptions } from 'ng-zorro-antd/notification/typings';
import { filter, interval, Subscription } from 'rxjs';
import {
    ButtonComponent,
    IconComponent,
    isNullOrUndefined,
    ModalUtils,
    tryToUnsubscribeFromSubscription
} from 'sfx-commons';
import { environment } from '../../../environments/environment';
import { TimePipe } from '../../common-utils/pipes/time.pipe';
import { LOGIN_QUERY, LOGIN_QUERY_LOGOUT_REASON_MAP, LOGOUT_REASON } from '../../constants';
import { PopupNotificationService } from '../../core/services/popup-notification.service';
import { RouterService } from '../../core/services/router.service';
import { UserInactivityService } from '../../core/services/user-inactivity.service';
import { ALREADY_CONFIRMED_LEAVING_PROJECT_NAVIGATION_EXTRAS } from '../../projects/edit-project/constants';
import { AuthenticationService, IAuthStatus } from '../../projects/services/authentication.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-session-status-notification',
    templateUrl: './session-status-notification.component.html',
    styleUrl: './session-status-notification.component.scss',
    imports: [NgTemplateOutlet, NgClass, NgIf, AsyncPipe, TranslatePipe, TimePipe, ButtonComponent, IconComponent]
})
export class SessionStatusNotificationComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('prolongNotificationTpl', { read: TemplateRef })
    public prolongNotificationTpl: TemplateRef<void>;
    @ViewChild('inactivityNotificationTpl', { read: TemplateRef })
    public inactivityNotificationTpl: TemplateRef<void>;
    @ViewChild('shortProlongNotificationTpl', { read: TemplateRef })
    public shortProlongNotificationTpl: TemplateRef<void>;
    @ViewChild('closeIconTpl', { read: TemplateRef })
    public closeIconTpl: TemplateRef<void>;

    @Output() public timerCompleted = new EventEmitter<void>();
    @Output() public prolongSessionClicked = new EventEmitter<void>();

    public hourglassIcon: IconDefinition = farHourglassStart;
    public logoutTimestamp: number;
    public userInactivityLogoutTimestamp: number;

    private prolongNotificationElement: Element;
    private inactivityNotificationElement: Element;
    private shortProlongNotificationElement: Element;
    private hourglassIconChangeIntervalId: ReturnType<typeof setInterval>;
    private authStatus: IAuthStatus;
    private prolongSessionDisplayTimestamp: number;
    private isUserInactiveSinceTimestamp: number;
    private isSessionProlongationDisplayed: boolean;
    private _timerInterval: Subscription;
    private _displayProlongNotificationTimeout: ReturnType<typeof setTimeout>;
    private _displayProlongShortNotificationTimeout: ReturnType<typeof setTimeout>;

    private readonly prolongSessionTimeBeforeExpiration = 10 * 60 * 1000;
    private readonly notificationKey = 'session-status-notification';
    private readonly inactivityNotificationKey = 'session-status-notification--inactivity';
    private readonly commonNotificationClassName = 'session-status__notification-wrapper';
    private readonly standardNotificationClassName = 'session-status__standard-notification-wrapper';
    private readonly inactivityNotificationClassName = `${this.standardNotificationClassName} session-status__standard-notification-wrapper--inactivity`;
    private readonly shortNotificationClassName = 'session-status__short-notification-wrapper';
    private readonly commonAnimationDuration = '400ms';
    private readonly commonNotificationOptions: NzNotificationDataOptions = {
        nzDuration: 0,
        nzPlacement: 'bottomLeft',
        nzAnimate: false,
        nzClass: this.commonNotificationClassName,
        nzKey: this.notificationKey
    };
    private readonly hourglassIconNextStates: Record<string, IconDefinition> = {
        [farHourglassStart.iconName]: farHourglassHalf,
        [farHourglassHalf.iconName]: farHourglassEnd,
        [farHourglassEnd.iconName]: farHourglassStart
    };

    private readonly slideUpAnimation: Array<AnimationMetadata> = [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate(`${this.commonAnimationDuration} ease-in-out`, style({ transform: 'translateY(0%)', opacity: 1 }))
    ];
    private readonly slideLeftAnimation: Array<AnimationMetadata> = [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('200ms ease-in', style({ transform: 'translateX(-100%)', opacity: 0 }))
    ];
    private readonly slideRightAnimation: Array<AnimationMetadata> = [
        style({ transform: 'translateX(-100%)', opacity: 0 }),
        animate(`${this.commonAnimationDuration} ease-in-out`, style({ transform: 'translateX(0%)', opacity: 1 }))
    ];

    protected readonly falTimer = falTimer;
    protected readonly falArrowLeft = falArrowLeft;

    constructor(
        private readonly animationBuilder: AnimationBuilder,
        private readonly authenticationSvc: AuthenticationService,
        private readonly inactivitySvc: UserInactivityService,
        private readonly modalService: NzModalService,
        private readonly popupNotificationSvc: PopupNotificationService,
        private readonly routerSvc: RouterService,
        private readonly translate: TranslateService
    ) {}

    public ngOnInit(): void {
        this.authenticationSvc.authStatus
            .pipe(untilDestroyed(this))
            .subscribe((authStatus: IAuthStatus) => this.processAuthStatusChange(authStatus));

        this.inactivitySvc.userInactivityObservable
            .pipe(
                untilDestroyed(this),
                filter(() => this.authStatus?.isAuthorized)
            )
            .subscribe((isInactive: boolean) => {
                const now = Date.now();
                if (isInactive) {
                    this.isUserInactiveSinceTimestamp = now;
                    this.userInactivityLogoutTimestamp = Math.min(
                        now + (environment.inactivityTimeout?.logout || 0),
                        this.logoutTimestamp || 0
                    );
                } else {
                    this.isUserInactiveSinceTimestamp = null;
                    this.userInactivityLogoutTimestamp = null;
                }

                void this.updateNotificationsState(now);
            });
    }

    public ngAfterViewInit(): void {
        this.commonNotificationOptions.nzCloseIcon = this.closeIconTpl;
    }

    public ngOnDestroy(): void {
        this.inactivitySvc.stopInactivityTracking();
        this.clearUpComponent();
    }

    private get timerInterval(): Subscription {
        return this._timerInterval;
    }

    private set timerInterval(delegate: () => Subscription) {
        tryToUnsubscribeFromSubscription(this._timerInterval);
        this._timerInterval = delegate();
    }

    private get displayProlongNotificationTimeout(): ReturnType<typeof setTimeout> {
        return this._displayProlongNotificationTimeout;
    }

    private set displayProlongNotificationTimeout(delegate: () => ReturnType<typeof setTimeout>) {
        clearTimeout(this._displayProlongNotificationTimeout);
        this._displayProlongNotificationTimeout = delegate();
    }

    private get displayProlongShortNotificationTimeout(): ReturnType<typeof setTimeout> {
        return this._displayProlongShortNotificationTimeout;
    }

    private set displayProlongShortNotificationTimeout(delegate: () => ReturnType<typeof setTimeout>) {
        clearTimeout(this._displayProlongShortNotificationTimeout);
        this._displayProlongShortNotificationTimeout = delegate();
    }

    private getNotificationElement(className: string): Element {
        const selectorClasses = className.replaceAll(' ', '.');

        return document.querySelector(`.${selectorClasses}`);
    }

    private checkIfAnyTimestampExpired(): void {
        const now = Date.now();

        if (
            (!!this.userInactivityLogoutTimestamp && this.userInactivityLogoutTimestamp <= now) ||
            (!!this.logoutTimestamp && this.logoutTimestamp <= now)
        ) {
            void this.onTimerCompleted();
            return;
        }

        void this.updateNotificationsState(now);
    }

    private setLogoutTimestamp(): void {
        this.logoutTimestamp = this.authStatus?.tokenExpiration || 0;
    }

    public async onProlongSessionClicked(): Promise<void> {
        this.clearUpComponent();
        await this.authenticationSvc.prolongSession();
    }

    public removeStandardProlongNotification(shouldDisplayShortProlongNotification = true): void {
        this.removeNotification(
            'prolongNotificationElement',
            this.notificationKey,
            this.slideLeftAnimation,
            shouldDisplayShortProlongNotification
                ? () =>
                      (this.displayProlongShortNotificationTimeout = () =>
                          setTimeout(() => {
                              if (this.authStatus?.isAuthorized) {
                                  this.displayShortNotification();
                              }
                          }, 300))
                : undefined
        );
    }

    public removeShortProlongNotification(): void {
        this.clearHourglassIconChangeInterval();
        this.removeNotification(
            'shortProlongNotificationElement',
            this.notificationKey,
            this.slideLeftAnimation,
            () =>
                (this.displayProlongNotificationTimeout = () =>
                    setTimeout(() => {
                        if (this.authStatus?.isAuthorized) {
                            this.displayStandardNotification();
                        }
                    }, 300))
        );
    }

    private processAuthStatusChange(authStatus: IAuthStatus): void {
        const oldAuthStatus = this.authStatus;
        this.authStatus = authStatus;
        this.setLogoutTimestamp();
        const didJustGetUnauthenticated =
            oldAuthStatus && oldAuthStatus.isAuthenticated && !this.authStatus?.isAuthenticated;

        if (this.authStatus?.isAuthenticated) {
            if (this.authStatus.isAuthorized) {
                this.processLoggedInImplications();
            } else {
                this.inactivitySvc.stopInactivityTracking();
            }
        } else {
            this.processLoggedOutImplications(didJustGetUnauthenticated);
        }
    }

    private processLoggedInImplications(): void {
        this.inactivitySvc.startInactivityTracking();
        this.setUpTokenLifecycleEventsForManualProlongation();

        this.timerInterval = () =>
            interval(1000)
                .pipe(untilDestroyed(this))
                .subscribe(() => this.checkIfAnyTimestampExpired());
    }

    private processLoggedOutImplications(didJustGetUnauthenticated: boolean): void {
        this.inactivitySvc.stopInactivityTracking();
        this.clearUpComponent();
        if (didJustGetUnauthenticated) {
            void this.onTimerCompleted();
        }
    }

    private setUpTokenLifecycleEventsForManualProlongation(): void {
        if (!this.authenticationSvc.doesIdentityProviderSupportRefreshToken(this.authStatus.activeProvider)) {
            // NO REFRESHING TOKEN SCENARIO
            if (!!this.authStatus.tokenExpiration) {
                this.prolongSessionDisplayTimestamp = Math.max(
                    this.authStatus.tokenExpiration - this.prolongSessionTimeBeforeExpiration,
                    Date.now()
                );
            }
        }
    }

    private async onProlongSessionTimeoutExpired(): Promise<void> {
        this.prolongSessionDisplayTimestamp = null;
        this.isSessionProlongationDisplayed = true;

        const { isConfirmedPromise } = ModalUtils.waitForConfirmation(
            this.modalService,
            this.translate.instant('auth.prolong_session.title'),
            this.translate.instant('auth.prolong_session.message'),
            this.translate.instant('auth.prolong_session.prolong'),
            this.translate.instant('auth.prolong_session.cancel'),
            undefined,
            undefined,
            undefined,
            undefined,
            1001
        );
        if (await isConfirmedPromise) {
            await this.onProlongSessionClicked();
        } else {
            this.displayStandardNotification();
        }
    }

    private async onTimerCompleted(): Promise<void> {
        this.clearUpComponent();
        await this.routerSvc.navigateToLogout({
            queryParams: {
                [LOGIN_QUERY_LOGOUT_REASON_MAP[LOGOUT_REASON.SESSION_EXPIRED]]: LOGOUT_REASON.SESSION_EXPIRED,
                [LOGIN_QUERY.RETURN_URL]: this.routerSvc.getValidReturnUrl(this.routerSvc.currentUrl)
            },
            ...ALREADY_CONFIRMED_LEAVING_PROJECT_NAVIGATION_EXTRAS
        });
    }

    private displayInactivityNotification(): void {
        if (this.inactivityNotificationElement) {
            return;
        }

        this.displayNotification(
            'inactivityNotificationElement',
            this.inactivityNotificationTpl,
            {
                ...this.commonNotificationOptions,
                nzClass: `${this.commonNotificationOptions.nzClass} ${this.inactivityNotificationClassName}`,
                nzKey: this.inactivityNotificationKey
            },
            this.slideUpAnimation
        );
    }

    public removeInactivityNotification(): void {
        this.removeNotification(
            'inactivityNotificationElement',
            this.inactivityNotificationKey,
            this.slideLeftAnimation
        );
    }

    private displayStandardNotification(): void {
        if (this.prolongNotificationElement) {
            return;
        }

        this.displayNotification(
            'prolongNotificationElement',
            this.prolongNotificationTpl,
            {
                ...this.commonNotificationOptions,
                nzClass: `${this.commonNotificationOptions.nzClass} ${this.standardNotificationClassName}`
            },
            this.slideUpAnimation
        );
    }

    private displayShortNotification(): void {
        if (this.shortProlongNotificationElement) {
            return;
        }

        this.displayNotification(
            'shortProlongNotificationElement',
            this.shortProlongNotificationTpl,
            {
                ...this.commonNotificationOptions,
                nzClass: `${this.commonNotificationOptions.nzClass} ${this.shortNotificationClassName}`
            },
            this.slideRightAnimation
        );

        this.startHourglassIconChangeInterval();
    }

    private displayNotification(
        contextPropName: string,
        notificationTemplate: TemplateRef<void>,
        notificationOptions: NzNotificationDataOptions,
        animation?: Array<AnimationMetadata>
    ): void {
        if (this[contextPropName] || !this.authStatus?.isAuthorized) {
            return;
        }

        if (notificationTemplate) {
            this.popupNotificationSvc.createNotificationWithTemplate(notificationTemplate, notificationOptions);
            this[contextPropName] = this.getNotificationElement(notificationOptions.nzClass as string);

            if (this[contextPropName] && animation) {
                this.animationBuilder.build(animation).create(this[contextPropName]).play();
            }
        }
    }

    private removeNotification(
        notificationContextPropName: string,
        notificationKey: string,
        animation?: Array<AnimationMetadata>,
        onNotificationRemovedFn?: () => void
    ): void {
        if (this[notificationContextPropName] && animation) {
            const animationPlayer = this.animationBuilder.build(animation).create(this[notificationContextPropName]);

            animationPlayer.onDone(() => {
                animationPlayer.destroy();
                this.popupNotificationSvc.removeNotification(notificationKey);

                if (onNotificationRemovedFn) {
                    onNotificationRemovedFn();
                }
            });
            animationPlayer.play();
        } else {
            this.popupNotificationSvc.removeNotification(notificationKey);

            if (onNotificationRemovedFn) {
                onNotificationRemovedFn();
            }
        }
        this[notificationContextPropName] = null;
    }

    private startHourglassIconChangeInterval(): void {
        this.clearHourglassIconChangeInterval();

        this.hourglassIconChangeIntervalId = setInterval(() => {
            this.hourglassIcon = this.hourglassIconNextStates[this.hourglassIcon?.iconName] || farHourglassStart;
        }, 800);
    }

    private clearHourglassIconChangeInterval(): void {
        if (this.hourglassIconChangeIntervalId) {
            clearInterval(this.hourglassIconChangeIntervalId);
        }
    }

    private removeAnyNotification(): void {
        this.removeNotification('prolongNotificationElement', this.notificationKey, this.slideLeftAnimation);
        this.removeNotification('shortProlongNotificationElement', this.notificationKey, this.slideLeftAnimation);
        this.clearHourglassIconChangeInterval();
        this.removeNotification(
            'inactivityNotificationElement',
            this.inactivityNotificationKey,
            this.slideLeftAnimation
        );
    }

    private updateNotificationsState(timestamp?: number): void {
        const now = timestamp || Date.now();

        if (!isNullOrUndefined(this.userInactivityLogoutTimestamp)) {
            if (this.isUserInactiveSinceTimestamp <= now) {
                if (this.isSessionProlongationDisplayed && this.prolongNotificationElement) {
                    this.removeStandardProlongNotification();
                }
                this.displayInactivityNotification();
            }
        } else {
            this.removeInactivityNotification();
        }

        if (!isNullOrUndefined(this.prolongSessionDisplayTimestamp)) {
            if (this.prolongSessionDisplayTimestamp <= now) {
                if (!this.isSessionProlongationDisplayed) {
                    void this.onProlongSessionTimeoutExpired();
                }
            }
        }
    }

    private clearUpComponent(): void {
        this.isSessionProlongationDisplayed = false;
        this.isUserInactiveSinceTimestamp = null;
        this.prolongSessionDisplayTimestamp = null;
        this.userInactivityLogoutTimestamp = null;
        tryToUnsubscribeFromSubscription(this.timerInterval);
        clearTimeout(this.displayProlongNotificationTimeout);
        clearTimeout(this.displayProlongShortNotificationTimeout);
        this.setLogoutTimestamp();
        this.removeAnyNotification();
    }
}
