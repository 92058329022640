<div
    class="news-bar"
    *ngIf="news?.length && (hasUnreadNews$ | async)"
>
    <span
        class="news-header-message"
        (click)="openModal()"
        >{{ 'global.news_available' | translate }}</span
    >
    <sfx-icon
        size="s"
        (click)="hideNews()"
        [icon]="xMarkIcon"
    ></sfx-icon>
</div>
