export enum GlobalPermission {
    GP_CreateProjects = 'GP_CreateProjects',
    GP_OwnProjects = 'GP_OwnProjects',
    GP_ViewCreatedProjects = 'GP_ViewCreatedProjects',
    GP_ViewOwnProjects = 'GP_ViewOwnProjects',
    GP_ViewSupervisorProjects = 'GP_ViewSupervisorProjects',
    GP_ViewProjectsOfEmployeesInTree = 'GP_ViewProjectsOfEmployeesInTree',
    GP_ViewAllProjectsOfSLSandSLAD = 'GP_ViewAllProjectsOfSLSandSLAD',
    GP_AdjustProjectsWithViewRights = 'GP_AdjustProjectsWithViewRights',
    GP_AdjustingOwnerWithViewRights = 'GP_AdjustingOwnerWithViewRights',
    GP_ViewProjectOwnerInfo = 'GP_ViewProjectOwnerInfo',
    GP_CanFilterByAdvisor = 'GP_CanFilterByAdvisor'
}
